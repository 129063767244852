import { gql } from '@apollo/client'

export const GQL_QUERY_VARIABLES = (timezone:string,filter:any,orderBy:any,paginacion:any) => {
    return {
        query: gql`query variables($timezone:String!,$filter:filter,$orderBy:[orderBy],$paginacion:paginacion){
                      variables(timezone:$timezone,filter:$filter,orderBy:$orderBy,paginacion:$paginacion){
                        ...on variables {
                          totalCount
                          data{
                            vari_id
                            vari_enti_id
                            vari_descripcion
                            vari_zero
                            vari_virtual
                            vari_k_voltaje
                            vari_k_corriente
                            vari_magn_id
                            vari_codigo
                            vari_serv_id
                            vari_defecto_unid_id
                            unid_enti_id
                            unid_nombre
                            unid_medida
                            unid_descripcion
                            unid_factor_multi_comun
                            vari_nombre
                            vari_activo
                            vari_creacion
                            magn_id
                            magn_enti_id
                            magn_descripcion
                            magn_nombre
                            magn_activo
                            magn_creacion
                          }
                        }
                        ...on msg{
                          mensaje
                        }
                      }
                    }
                  `,
        variables: {
          timezone,filter,orderBy,paginacion
        },
        //fetchPolicy:"network-only"
      }
}

export const GQL_MUTATION_INSERT_VARIABLE = (timezone:string,fields:any) => {
  return {
    mutation: gql`mutation insert($timezone:String!,$fields:fieldVariable){
      insertVariable(timezone:$timezone,fields:$fields){
        vari_id
        vari_enti_id
        vari_descripcion
        vari_zero
        vari_virtual
        vari_k_voltaje
        vari_k_corriente
        vari_magn_id
        vari_codigo
        vari_serv_id
        vari_defecto_unid_id
        unid_enti_id
        unid_nombre
        unid_medida
        unid_descripcion
        unid_factor_multi_comun
        vari_nombre
        vari_activo
        vari_creacion
        magn_id
        magn_enti_id
        magn_descripcion
        magn_nombre
        magn_activo
        magn_creacion
      }
    }`,
    variables: {
      timezone,fields
    },
  }
}

export const GQL_MUTATION_UPDATE_VARIABLE = (timezone:string,filter:any,fields:any) => {
  return {
    mutation: gql`mutation update($timezone:String!,$filter:filter,$fields:fieldVariable){
      updateVariable(timezone:$timezone,filter:$filter,fields:$fields){
        ...on variables {
          totalCount
          data{
            vari_id
            vari_enti_id
            vari_descripcion
            vari_zero
            vari_virtual
            vari_k_voltaje
            vari_k_corriente
            vari_magn_id
            vari_codigo
            vari_serv_id
            vari_defecto_unid_id
            unid_enti_id
            unid_nombre
            unid_medida
            unid_descripcion
            unid_factor_multi_comun
            vari_nombre
            vari_activo
            vari_creacion
            magn_id
            magn_enti_id
            magn_descripcion
            magn_nombre
            magn_activo
            magn_creacion
          }
        }
      ...on msg{
        mensaje
      }
      }
    }`,
    variables: {
      timezone,filter,fields
    },
    ///fetchPolicy:"network-only"
  }
}

export const GQL_MUTATION_DELETE_VARIABLE = (timezone:string,filter:any) => {
  console.log(filter)
  return {
    mutation: gql`mutation delete($timezone:String!,$filter:filter){
      deleteVariable(timezone:$timezone,filter:$filter){
        ...on variables {
                      totalCount
                      data{
                        vari_id
                        vari_enti_id
                        vari_descripcion
                        vari_zero
                        vari_virtual
                        vari_k_voltaje
                        vari_k_corriente
                        vari_magn_id
                        vari_codigo
                        vari_serv_id
                        vari_defecto_unid_id
                        unid_enti_id
                        unid_nombre
                        unid_medida
                        unid_descripcion
                        unid_factor_multi_comun
                        vari_nombre
                        vari_activo
                        vari_creacion
                        magn_id
                        magn_enti_id
                        magn_descripcion
                        magn_nombre
                        magn_activo
                        magn_creacion
                      }
                    }
      ...on msg{
        mensaje
      }
      }
    }`,
    variables: {
      timezone,filter
    },
    ///fetchPolicy:"network-only"
  }
}

