/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { HeaderToolbar } from './HeaderToolbar'
import { Topbar } from './Topbar'
import { useHeaderToolbarContext } from './UIContext'
import { useMemo } from 'react'
import { ThemeModeSwitcher } from '../../../partials'
//import {HeaderToolbarProvider} from './UIContext'

export function HeaderWrapper() {
  const { config, classes, attributes } = useLayout()
  const { aside } = config



  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      {/* begin::Brand */}
      <div className='header-brand'>
        {/* begin::Logo */}
        <Link to='/'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/ribe-logo2.png')}
            className='h-50px h-lg-50px'
          />
        </Link>
        {/* end::Logo */}

        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr092.svg'
              className='svg-icon-1 me-n1 minimize-default'
            />
            <KTSVG
              path='/media/icons/duotune/arrows/arr076.svg'
              className='svg-icon-1 minimize-active'
            />
          </div>
        )}

        {/* begin::Aside toggle */}
        <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-30px h-30px'
            id='kt_aside_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-1' />
          </div>
        </div>
        {/* end::Aside toggle */}
      </div>
      {/* end::Brand */}
      {/*<HeaderToolbarProvider>*/}
      <HeaderToolbar />
      {/*</HeaderToolbarProvider>*/}



          <Topbar />


      <div className='d-flex align-items-center ms-1 ms-lg-3'>
        {/* begin::Label */}
        <span className='fs-7 text-gray-700 fw-bolder pe-3 d-none d-xxl-block'>
          Brillo:
        </span>
        {/* end::Label */}

        {/* begin::Actions */}
        <div className='d-flex'>
          {/* begin::Theme mode */}
          <div className='d-flex align-items-center'>
            <ThemeModeSwitcher toggleBtnClass='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary' />
          </div>
          {/* end::Theme mode */}
        </div>
        {/* end::Actions */}
      </div>
    </div>
  )
}
