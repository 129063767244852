export const renderOptions = (state: any, keySelected: any, msg:string) => {
    
    /*if (formik !== undefined && keySelected === undefined && state.data.length > 0 && keyFieldName !== ''){
        formik.setFieldValue(keyFieldName,state.data[0].value)
    }*/
    return [<option key='1' value="">{msg}</option>].concat(state.data.map((item: any) => 
    (<option key={item.value} value={`${item.value}`} selected={((keySelected && keySelected.includes(item.value)))}>
        {item.label}
    </option>)))
}

export const renderOptions2 = (state: any, msg:string) => {
    
    /*if (formik !== undefined && keySelected === undefined && state.data.length > 0 && keyFieldName !== ''){
        formik.setFieldValue(keyFieldName,state.data[0].value)
    }*/
    return [<option key='1' value="">{msg}</option>].concat(state.data.map((item: any) => 
    (<option key={item.value} value={`${item.value}`}>
        {item.label}
    </option>)))
}

export {}