import { gql } from '@apollo/client'

export const GQL_QUERY_EDIFICIOS = (timezone:string,filter:any,orderBy:any,paginacion:any) => {
    return {
        query: gql`query edificios($timezone:String!,$filter:filter,$orderBy:[orderBy],$paginacion:paginacion){
                      edificios(timezone:$timezone,filter:$filter,orderBy:$orderBy,paginacion:$paginacion){
                        ...on edificios {
                          totalCount
                          data{
                            edif_id
                            edif_enti_id
                            edif_prefijo
                            edif_direccion
                            edif_ciud_id
                            edif_nombre
                            edif_activo
                            edif_creacion
                            ciud_nombre
                            regi_nombre
                            zona_nombre
                            pais_nombre
                            edif_telegram_notificaciones
                          }
                        }
                        ...on msg{
                          mensaje
                        }
                      }
                    }
                  `,
        variables: {
          timezone,filter,orderBy,paginacion
        },
        //fetchPolicy:"network-only"
      }
}

export const GQL_MUTATION_INSERT_EDIFICIO = (timezone:string,fields:any) => {
  return {
    mutation: gql`mutation insert($timezone:String!,$fields:fieldEdificio){
      insertEdificio(timezone:$timezone,fields:$fields){
        edif_id
        edif_enti_id
        edif_prefijo
        edif_direccion
        edif_ciud_id
        edif_nombre
        edif_activo
        edif_creacion
        ciud_nombre
        regi_nombre
        zona_nombre
        pais_nombre
        edif_telegram_notificaciones
      }
    }`,
    variables: {
      timezone,fields
    },
  }
}

export const GQL_MUTATION_UPDATE_EDIFICIO = (timezone:string,filter:any,fields:any) => {
  return {
    mutation: gql`mutation update($timezone:String!,$filter:filter,$fields:fieldEdificio){
      updateEdificio(timezone:$timezone,filter:$filter,fields:$fields){
        ...on edificios {
          totalCount
          data{
            edif_id
            edif_enti_id
            edif_prefijo
            edif_direccion
            edif_ciud_id
            edif_nombre
            edif_activo
            edif_creacion
            ciud_nombre
            regi_nombre
            zona_nombre
            pais_nombre
            edif_telegram_notificaciones
          }
        }
      ...on msg{
        mensaje
      }
      }
    }`,
    variables: {
      timezone,filter,fields
    },
    ///fetchPolicy:"network-only"
  }
}

export const GQL_MUTATION_DELETE_EDIFICIO = (timezone:string,filter:any) => {
  console.log(filter)
  return {
    mutation: gql`mutation delete($timezone:String!,$filter:filter){
      deleteEdificio(timezone:$timezone,filter:$filter){
        ...on edificios {
                      totalCount
                      data{
                        edif_id
                        edif_enti_id
                        edif_prefijo
                        edif_direccion
                        edif_ciud_id
                        edif_nombre
                        edif_activo
                        edif_creacion
                        ciud_nombre
                        regi_nombre
                        zona_nombre
                        pais_nombre
                        edif_telegram_notificaciones
                      }
                    }
      ...on msg{
        mensaje
      }
      }
    }`,
    variables: {
      timezone,filter
    },
    ///fetchPolicy:"network-only"
  }
}

