import { Variable, VariablesQueryResponse } from './_models'
import { getPagination } from '../../../../../utils'
import Client from '../../../../../graphql/client'
import * as Queries from "./_gql"

export function getAllVariables({timezone='UTC',filter,orderBy}:any) {
  return Client.query(Queries.GQL_QUERY_VARIABLES(timezone,filter || {},orderBy||[{field:"vari_nombre",type:"asc"}],{}));
}


const getVariables = ({timezone,params}:any): Promise<VariablesQueryResponse> => {
  let condiciones: any = []
  if (params.search && params.search !== "")
    condiciones.push({ field: "vari_nombre", operador: "ILIKE", value: params.search })
  if (params.filter)
    Object.keys(params.filter).forEach((key: any) => {
      condiciones.push({ field: key, value: params.filter[key] })
    })

  return Client.query(Queries.GQL_QUERY_VARIABLES(timezone,(condiciones.length > 0) ? { condiciones: condiciones } : {}, [{ field: params.sort || "vari_creacion", type: params.order || "desc" }], { pagina: params.page || 1, cantidad: params.items_per_page })).then((d: any) => {
    return {
      data: d.data.variables.data,
      payload: {
        pagination: getPagination(params, d.data.variables.totalCount)
      }
    }
  });
  /*return axios
    .get(`${GET_VARIABLES_URL}?${query}`)
    .then((d: AxiosResponse<VariablesQueryResponse>) => d.data)*/
}

const getVariableById = ({timezone = 'UTC',vari_id}: any): Promise<Variable | undefined> => {
  return Client.query(Queries.GQL_QUERY_VARIABLES(timezone,{ condiciones: [{ field: "vari_id", value: vari_id }] }, [], {})).then((d: any) => {
    return d.data.variables.data[0]
  });
  /*return axios
    .get(`${VARIABLE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Variable>>) => response.data)
    .then((response: Response<Variable>) => response.data)*/
}

const createVariable = ({timezone = 'UTC',values}: any): Promise<Variable | undefined> => {
  const {vari_nombre,vari_descripcion,vari_magn_id,vari_zero,vari_virtual,vari_k_corriente,vari_k_voltaje,vari_codigo,vari_serv_id,vari_defecto_unid_id,vari_activo} = values
   return Client.mutate(Queries.GQL_MUTATION_INSERT_VARIABLE(timezone,{ 
    vari_nombre, 
    vari_descripcion,
    vari_magn_id,
    vari_activo:(vari_activo)?Number(vari_activo):vari_activo,
    vari_zero:(vari_zero)?Number(vari_zero):vari_zero,
    vari_virtual:(vari_virtual)?Number(vari_virtual):vari_virtual, 
    vari_k_corriente:(vari_k_corriente)?Number(vari_k_corriente):vari_k_corriente,
    vari_k_voltaje:(vari_k_voltaje)?Number(vari_k_voltaje):vari_k_voltaje,
    vari_codigo,
    vari_serv_id,
    vari_defecto_unid_id

   }
    )).then((d: any) => {
    return d.data.insertVariable
  });
  /*return axios
    .put(VARIABLE_URL, variable)
    .then((response: AxiosResponse<Response<Variable>>) => response.data)
    .then((response: Response<Variable>) => response.data)*/
}

const updateVariable = ({timezone = 'UTC',values}:any): Promise<Variable | undefined> => {
  const {vari_nombre,vari_descripcion,vari_magn_id,vari_zero,vari_virtual,vari_k_corriente,vari_k_voltaje,vari_codigo,vari_serv_id,vari_defecto_unid_id,vari_activo} = values
  return Client.mutate(Queries.GQL_MUTATION_UPDATE_VARIABLE(timezone,{ 
    condiciones: [{ field: "vari_id", value: values.vari_id }] }, 
    { 
      vari_nombre, 
      vari_descripcion,
      vari_magn_id,
      vari_activo:(vari_activo)?Number(vari_activo):vari_activo,
      vari_zero:(vari_zero)?Number(vari_zero):vari_zero,
      vari_virtual:(vari_virtual)?Number(vari_virtual):vari_virtual, 
      vari_k_corriente:(vari_k_corriente)?Number(vari_k_corriente):vari_k_corriente,
      vari_k_voltaje:(vari_k_voltaje)?Number(vari_k_voltaje):vari_k_voltaje,
      vari_codigo,
      vari_serv_id,
      vari_defecto_unid_id   
    })).then((d: any) => {
    return undefined
  })
  /*return axios
    .post(`${VARIABLE_URL}/${variable.id}`, variable)
    .then((response: AxiosResponse<Response<Variable>>) => response.data)
    .then((response: Response<Variable>) => response.data)*/
}

const deleteVariable = ({timezone = 'UTC',vari_id}: any): Promise<void> => {
  return Client.mutate(Queries.GQL_MUTATION_DELETE_VARIABLE(timezone,{ condiciones: [{ field: "vari_id", value: vari_id }] })).then((d: any) => {
    return d
  })
  //return axios.delete(`${VARIABLE_URL}/${variableId}`).then(() => {})
}

const deleteSelectedVariables = ({timezone = 'UTC',vari_ids}: any): Promise<void> => {
  return Client.mutate(Queries.GQL_MUTATION_DELETE_VARIABLE(timezone,{ condiciones: [{ field: "vari_id", operador: "IN", value: vari_ids.map((i: any) => `'${i}'`).join(',') }] })).then((d: any) => {
    return d
  })

  /*const requests = variableIds.map((id) => axios.delete(`${VARIABLE_URL}/${id}`))
  return axios.all(requests).then(() => {})*/
}

export { getVariables, deleteVariable, deleteSelectedVariables, getVariableById, createVariable, updateVariable }
