import { gql } from '@apollo/client'

export const GQL_QUERY_UNIDADES = (timezone:string,filter:any,orderBy:any,paginacion:any) => {
    return {
        query: gql`query unidades($timezone:String!,$filter:filter,$orderBy:[orderBy],$paginacion:paginacion){
                      unidades(timezone:$timezone,filter:$filter,orderBy:$orderBy,paginacion:$paginacion){
                        ...on unidades {
                          totalCount
                          data{
                            unid_id
                            unid_enti_id
                            unid_nombre
                            unid_medida
                            unid_descripcion
                            unid_activo
                            unid_creacion
                            unid_factor_multi_comun
                            unid_defecto
                            unid_magn_id
                            magn_nombre
                            magn_activo
                          }
                        }
                        ...on msg{
                          mensaje
                        }
                      }
                    }
                  `,
        variables: {
          timezone,filter,orderBy,paginacion
        },
        //fetchPolicy:"network-only"
      }
}

export const GQL_QUERY_MONEDAS = (timezone:string, moneda:string,filter:any,orderBy:any,paginacion:any) => {
  return {
    query: gql`query monedas($timezone:String!,$moneda:String!,$filter:filter,$orderBy:[orderBy],$paginacion:paginacion){
                  monedas(timezone:$timezone,moneda:$moneda,filter:$filter,orderBy:$orderBy,paginacion:$paginacion){
                    ...on unidades {
                      totalCount
                      data{
                        unid_id
                        unid_enti_id
                        unid_medida
                        unid_descripcion
                        unid_nombre
                        unid_activo
                        unid_creacion
                        unid_magn_id
                        magn_enti_id
                        magn_nombre
                        magn_descripcion
                        unid_factor_multi_comun
                        unid_defecto
                      }
                    }
                    ...on msg{
                      mensaje
                    }
                  }
                }
              `,
    variables: {
      timezone,filter,orderBy,paginacion,moneda
    },
    //fetchPolicy:"network-only"
  }
}

export const GQL_MUTATION_INSERT_UNIDAD = (timezone:string,fields:any) => {
  return {
    mutation: gql`mutation insert($timezone:String!,$fields:fieldUnidad){
      insertUnidad(timezone:$timezone,fields:$fields){
        unid_id
        unid_enti_id
        unid_nombre
        unid_medida
        unid_descripcion
        unid_activo
        unid_creacion
        unid_factor_multi_comun
        unid_defecto
        unid_magn_id
        magn_nombre
        magn_activo
      }
    }`,
    variables: {
      timezone,fields
    },
  }
}

export const GQL_MUTATION_UPDATE_UNIDAD = (timezone:string,filter:any,fields:any) => {
  return {
    mutation: gql`mutation update($timezone:String!,$filter:filter,$fields:fieldUnidad){
      updateUnidad(timezone:$timezone,filter:$filter,fields:$fields){
        ...on unidades {
          totalCount
          data{
            unid_id
            unid_enti_id
            unid_nombre
            unid_medida
            unid_descripcion
            unid_activo
            unid_creacion
            unid_factor_multi_comun
            unid_defecto
            unid_magn_id
            magn_nombre
            magn_activo
          }
        }
      ...on msg{
        mensaje
      }
      }
    }`,
    variables: {
      timezone,filter,fields
    },
    ///fetchPolicy:"network-only"
  }
}

export const GQL_MUTATION_DELETE_UNIDAD = (timezone:string,filter:any) => {
  return {
    mutation: gql`mutation delete($timezone:String!,$filter:filter){
      deleteUnidad(timezone:$timezone,filter:$filter){
        ...on unidades {
                      totalCount
                      data{
                        unid_id
                        unid_enti_id
                        unid_nombre
                        unid_medida
                        unid_descripcion
                        unid_activo
                        unid_creacion
                        unid_factor_multi_comun
                        unid_defecto
                        unid_magn_id
                        magn_nombre
                        magn_activo
                      }
                    }
      ...on msg{
        mensaje
      }
      }
    }`,
    variables: {
      timezone,filter
    },
    ///fetchPolicy:"network-only"
  }
}

