import { gql } from '@apollo/client'

export const GQL_QUERY_EFICIENCIAS_PERMITIDAS = (filter, orderBy, paginacion) => {
  return {
    query: gql`query eficienciasPermitidas($filter:filter,$orderBy:[orderBy],$paginacion:paginacion){
                  eficienciasPermitidas(filter:$filter,orderBy:$orderBy,paginacion:$paginacion){
                    ...on eficienciasPermitidas {
                      totalCount
                      data{
                        perm_id
                        perm_enti_id
                        perm_usua_id
                        perm_defecto
                        perm_propietario
                        efic_id
                        efic_tabl_id
                        efic_edif_id
                        efic_principal_inst_id
                        efic_principal_potencia_vari_id
                        efic_lista_inst_id
                        efic_dias_atras
                        efic_tabl_enti_id
                        efic_descripcion
                        efic_nombre
                        efic_creacion
                        efic_activo
                      }
                    }
                    ...on msg{
                      mensaje
                    }
                  }
                }
              `,
    variables: {
      filter: filter, orderBy: orderBy, paginacion: paginacion,
    },
    //fetchPolicy:"network-only"
  }
}

export const GQL_QUERY_VARIABLE_HISTORICA = (parametros) => {
  return {
    query: gql`query Query($parametros: JSONObject) {
    widgVariableHistorica(parametros: $parametros)
  }
  `,
    variables: {
      parametros
    },

  }
}

export const GQL_QUERY_VARIABLE_HISTORICA_PERIODO = (parametros) => {
  return {
    query: gql`query Query($parametros: JSONObject) {
    widgVariableHistoricaPeriodo(parametros: $parametros)
  }
  `,
    variables: {
      parametros
    },

  }
}


export const GQL_QUERY_VARIABLE_HISTORICA_PERIODO_COMPARATIVO = (parametros) => {
  return {
    query: gql`query Query($parametros: JSONObject) {
    widgVariableHistoricaPeriodoComparativo(parametros: $parametros)
  }
  `,
    variables: {
      parametros
    },

  }
}











