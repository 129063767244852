import { Edificio, EdificiosQueryResponse } from './_models'
import { getPagination } from '../../../../../utils'
import Client from '../../../../../graphql/client'
import * as Queries from "./_gql"

export function getAllEdificios({timezone='UTC', filter, orderBy }: any) {
  return Client.query(Queries.GQL_QUERY_EDIFICIOS(timezone,filter || {}, orderBy || [{ field: "edif_nombre", type: "asc" }], {}));
}


const getEdificios = ({timezone,params}: any): Promise<EdificiosQueryResponse> => {
  let condiciones: any = []
  if (params.search && params.search !== "")
    condiciones.push({ field: "edif_nombre", operador: "ILIKE", value: params.search })
  if (params.filter)
    Object.keys(params.filter).forEach((key: any) => {
      condiciones.push({ field: key, value: params.filter[key] })
    })

  return Client.query(Queries.GQL_QUERY_EDIFICIOS(timezone,(condiciones.length > 0) ? { condiciones: condiciones } : {}, [{ field: params.sort || "edif_creacion", type: params.order || "desc" }], { pagina: params.page || 1, cantidad: params.items_per_page })).then((d: any) => {
    return {
      data: d.data.edificios.data,
      payload: {
        pagination: getPagination(params, d.data.edificios.totalCount)
      }
    }
  });
  /*return axios
    .get(`${GET_EDIFICIOS_URL}?${query}`)
    .then((d: AxiosResponse<EdificiosQueryResponse>) => d.data)*/
}

const getEdificioById = ({timezone,edif_id}: any): Promise<Edificio | undefined> => {
  return Client.query(Queries.GQL_QUERY_EDIFICIOS(timezone,{ condiciones: [{ field: "edif_id", value: edif_id }] }, [], {})).then((d: any) => {
    return d.data.edificios.data[0]
  });
  /*return axios
    .get(`${EDIFICIO_URL}/${id}`)
    .then((response: AxiosResponse<Response<Edificio>>) => response.data)
    .then((response: Response<Edificio>) => response.data)*/
}

const createEdificio = ({timezone,values}:any): Promise<Edificio | undefined> => {
  const { edif_nombre, edif_prefijo, edif_direccion, edif_ciud_id, edif_activo,edif_telegram_notificaciones } = values
  return Client.mutate(Queries.GQL_MUTATION_INSERT_EDIFICIO(timezone,{ edif_nombre, edif_prefijo, edif_direccion, edif_ciud_id, edif_activo: (edif_activo) ? Number(edif_activo) : edif_activo,edif_telegram_notificaciones })).then((d: any) => {
    return d.data.insertEdificio
  });
  /*return axios
    .put(EDIFICIO_URL, edificio)
    .then((response: AxiosResponse<Response<Edificio>>) => response.data)
    .then((response: Response<Edificio>) => response.data)*/
}

const updateEdificio = ({timezone = 'UTC',values}:any): Promise<Edificio | undefined> => {
  console.log(values)
  const {edif_nombre,edif_prefijo,edif_direccion,edif_ciud_id,edif_activo,edif_telegram_notificaciones} = values
  return Client.mutate(Queries.GQL_MUTATION_UPDATE_EDIFICIO(timezone,{ condiciones: [{ field: "edif_id", value: values.edif_id }] }, { edif_nombre, edif_prefijo, edif_direccion, edif_ciud_id, edif_activo: (edif_activo) ? Number(edif_activo) : edif_activo,edif_telegram_notificaciones })).then((d: any) => {
    return undefined
  })
  /*return axios
    .post(`${EDIFICIO_URL}/${edificio.id}`, edificio)
    .then((response: AxiosResponse<Response<Edificio>>) => response.data)
    .then((response: Response<Edificio>) => response.data)*/
}

const deleteEdificio = ({timezone,edif_id}:any): Promise<void> => {
  return Client.mutate(Queries.GQL_MUTATION_DELETE_EDIFICIO(timezone,{ condiciones: [{ field: "edif_id", value: edif_id }] })).then((d: any) => {
    return d
  })
  //return axios.delete(`${EDIFICIO_URL}/${edificioId}`).then(() => {})
}

const deleteSelectedEdificios = ({timezone,edif_ids}:any): Promise<void> => {
  return Client.mutate(Queries.GQL_MUTATION_DELETE_EDIFICIO(timezone,{ condiciones: [{ field: "edif_id", operador: "IN", value: edif_ids.map((i: any) => `'${i}'`).join(',') }] })).then((d: any) => {
    return d
  })

  /*const requests = edificioIds.map((id) => axios.delete(`${EDIFICIO_URL}/${id}`))
  return axios.all(requests).then(() => {})*/
}

export { getEdificios, deleteEdificio, deleteSelectedEdificios, getEdificioById, createEdificio, updateEdificio }
