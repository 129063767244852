import * as XLSX from "xlsx-js-style"
import { isNumber } from '../helpers'

const fixWidth = (ws: XLSX.WorkSheet, data: any) => {
    //const data = XLSX.utils.sheet_to_json<any>(worksheet)
    const colLengths = Object.keys(data[0]).map((k) => k.toString().length + 5)
    for (const d of data) {
        Object.values(d).forEach((element: any, index) => {
            const length = (((element !== undefined) && (element !== null))?element.toString().length:0) + 5
            if (colLengths[index] < length) {
                colLengths[index] = length
            }
        })
    }
    ws["!cols"] = colLengths.map((l) => {
        return {
            wch: l,
        }
    })
}

const setHeader = (ws: XLSX.WorkSheet, data: any[], columns: readonly any[]) => {
    let oldValue: string = ""
    ws["!rows"] = [{ hpx: 20 }] //Primera Fila (Cabecera asignar Ancho de fila =20)
    const defaultStyle = { font: { name: "Verdana", sz: 12, bold: true, color: { rgb: "FFFFFF" } }, fill: { fgColor: { rgb: "113d76" } } }
    for (var cols = 0; cols < columns.length; cols++) {
      let celda: string = XLSX.utils.encode_cell({ c: cols, r: 0 })
      oldValue = ws[celda].v
      ws[celda] = {
        v: (columns[cols]?.exportOpts?.title) ? columns[cols]?.exportOpts?.title : oldValue,
        s: (columns[cols]?.exportOpts.style) ? columns[cols]?.exportOpts.style : defaultStyle
      }
    }
  }
  
  const setBody = (ws: XLSX.WorkSheet, data: any[], columns: readonly any[]) => {
    for (var rows = 1; rows <= data.length; rows++) { //Recorrer todas las filas de datos
      ws["!rows"]?.push({ hpx: 15 }) //Asignar ancho de fila = 15 
      for (var cols = 0; cols < columns.length; cols++) { //Recorrer todas las columnas de datos
        let celda: string = XLSX.utils.encode_cell({ c: cols, r: rows }) //codificar celdas al formato de excel ejemL A1, C34, Z45
        /******************Valor de la Celda *****************/
        let oldValue = (ws[celda].v !== null)?String(ws[celda].v).replace(",", "."):"--"
        console.log('oldValue:',oldValue)
        let esNumero = isNumber(oldValue)
        console.log('esNumero:',esNumero)
        ws[celda].v = (esNumero) ? Number(oldValue) : oldValue
        /****************** Formato Numerico *****************/
        ws[celda].z = (columns[cols]?.exportOpts?.format) ? columns[cols]?.exportOpts?.format : ""
        /****************** Tipo de Dato de Celda ************/
        ws[celda].t = (esNumero) ? "n" : "s"
        /****************** Estilo de Celda ******************/
        ws[celda].s = (columns[cols]?.exportOpts?.alignment) ? { alignment: { horizontal: columns[cols]?.exportOpts?.alignment } } : (esNumero) ? { alignment: { horizontal: "right" } } : { alignment: { horizontal: (oldValue !== "--")?"left":"center" } }
      }
    }
  }
  
  const setFooter = (ws: XLSX.WorkSheet, data: any[], columns: readonly any[]) => {
    ws["!rows"]?.push({ hpx: 20 })
    const defaultStyle = { font: { name: "Verdana", sz: 12, bold: true, color: { rgb: "FFFFFF" } }, fill: { fgColor: { rgb: "113d76" } } }
    for (var cols = 0; cols < columns.length; cols++) {
      let celda: string = XLSX.utils.encode_cell({ c: cols, r: data.length + 1 })
      XLSX.utils.sheet_add_aoa(ws, [['']], { origin: celda });
      let rango = XLSX.utils.encode_range({ s: { c: cols, r: 1 }, e: { c: cols, r: data.length } })
      ws[celda] = {
        v:"",
        f: ((columns[cols]?.exportOpts?.sum) && (columns[cols]?.exportOpts?.sum === true))?`SUM(${rango})`:"",
        t:"s",
        z: "#,##0.00",
        s: (columns[cols]?.exportOpts.style) ? columns[cols]?.exportOpts.style : defaultStyle
      }
    }
  }
  
  export const downloadExcel = (data: any[], Columns:readonly any[], fileName:string = "archivo.xlsx",sheetName:string = "sheet") => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    setHeader(worksheet, data, Columns)
    setBody(worksheet, data, Columns)
    setFooter(worksheet,data,Columns)
    fixWidth(worksheet, data)
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    XLSX.writeFile(workbook, fileName);
  };
