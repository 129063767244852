import { useThemeMode } from "../../../../../_metronic/partials"
import "./loader1.css"



const ListLoading2 = () => {
  const { menuMode } = useThemeMode()
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: (menuMode !== 'dark') ? 'rgba(255,255,255,0.7)' : 'rgba(73,73,94,0.7)',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  //return <div style={{...styles, position: 'absolute', textAlign: 'center'}}>Procesando...</div>
  return <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>Procesando...</div>
}

const ListLoading = () => {
  const { menuMode } = useThemeMode()
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: (menuMode !== 'dark') ? 'rgba(255,255,255,0.7)' : 'rgba(73,73,94,0.7)',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }
  return (
    <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>
      <div className="loader">
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
        <div className="bar4"></div>
        <div className="bar5"></div>
      </div>
      <div>
        Procesando...
      </div>
    </div>
  )


}
export { ListLoading }
