import {createSlice} from "@reduxjs/toolkit";

const initialServiciosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  servicioForEdit: undefined,
  lastError: null,
  queryParams: undefined
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const serviciosSlice = createSlice({
  name: "servicios",
  initialState: initialServiciosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProductById
    servicioFetched: (state, action) => {
      state.actionsLoading = false;
      state.servicioForEdit = action.payload.servicioForEdit;
      state.error = null;
    },
    // findProducts
    serviciosFetched: (state, action) => {
      const { queryParams, totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.queryParams = queryParams;
    },
    // createProduct
    servicioCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.servicio);
    },
    // updateProduct
    servicioUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.serv_id === action.payload.servicio.serv_id) {
          return action.payload.servicio;
        }
        return entity;
      });
    },
    // deleteProduct
    servicioDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.serv_id !== action.payload.serv_id);
    },
    // deleteProducts
    serviciosDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.serv_id)
      );
    },
    // productsUpdateState
    serviciosStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(serv_id => serv_id === entity.serv_id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
