import { gql } from '@apollo/client'

export const _Login = (email:string, password:string) => {
  return {
    mutation: gql`mutation login($usua_login:String!,$usua_passwd:String!){
            login(usua_login:$usua_login,usua_passwd:$usua_passwd){
                ...on tokens{
                    accessToken
                    refreshToken
                }
            }
        }`,
    variables: { usua_login: email, usua_passwd: password }
  }
}

export const _GetUserByAccessToken = (accessToken:string) => {
    return {
      query: gql`query usuarioByAccessToken($accessToken:String!){
          usuarioByAccessToken(accessToken:$accessToken){
                      ...on usuarios {
                        data{
                          usua_id
                          usua_enti_id
                          usua_cambiar
                          usua_login
                          usua_activo
                          usua_creacion
                          usua_timezone
                          uspe_id
                          uspe_usua_id
                          uspe_pers_id
                          uspe_desde
                          uspe_hasta
                          pers_enti_id
                          pers_rut
                          pers_nombres
                          pers_apellidos
                          pers_email
                          pers_telefonos
                          pers_direccion
                          pers_activo
                          pers_creacion
                          empl_id
                          empl_puem_id
                          empl_pers_id
                          empl_desde
                          empl_hasta
                          puem_id
                          puem_empr_id
                          puem_pues_id
                          puem_activo
                          puem_creacion
                          pues_id
                          pues_enti_id
                          pues_nombre
                          pues_activo
                          pues_creacion
                          empr_id
                          empr_enti_id
                          empr_nombre
                          empr_activo
                          empr_creacion
                          empr_rut
                          empr_direccion
                          empr_email
                          empr_telefonos
                          empr_web
                          modulos{
                            ...on modulos_usuarios{
                              data{
                                pemo_modu_id
                                modu_enti_id
                                modu_nombre
                                modu_activo
                                modu_descripcion
                                modu_padre_id
                                modu_posicion
                                modu_ruta
                                modu_icono
                                pemo_activo
                                pemo_view
                                pemo_insert
                                pemo_update
                                pemo_delete
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                `,
      variables: { accessToken: accessToken }
    }
  }