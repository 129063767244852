import React from "react";
import { HalfCircleSpinner } from 'react-epic-spinners'
import './css/loader.css';
import 'csspin/css/csspin-balls.css'

export const Loading2 = ({ mode, loading, msg = undefined, children }) => {
    return (
        <>
            <div
                className="row justify-content-center align-items-center"
                style={{
                    height: '100%',
                    width: '100%',
                    //backgroundColor: (mode !== 'dark') ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)',

                }}>
                <span className='text-center' style={{
                    position: 'absolute',
                    verticalAlign: 'middle',
                    display: ((loading === true)) ? "table" : "none"
                }} >
                    <div className="cp-spinner cp-balls"></div>
                    <div><b>{`${(msg) ? msg : "Cargando data..."}`}</b></div>
                </span>
                {children}
            </div>
        </>
    )
}

export function Loading({ mode, loading, msg = undefined, children }) {
    return (
        <>
            <div className="row" style={{ height: '100%', width: '100%' }}>
                <div className="col-lg-12 text-center" style={{ height: '100%', width: '100%' }}>
                    <div className='col text-center' style={{
                        position: 'absolute',
                        //zIndex: 999,
                        top: 0,
                        left: 5,

                        height: '100%',
                        width: '100%',
                        backgroundColor: (mode !== 'dark') ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)',
                        display: ((loading === true)) ? "table" : "none"
                    }}>
                        <span className='text-center' style={{
                            display: 'table-cell', verticalAlign: 'middle'
                        }} >
                            {/*<div id="preloader_4">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                    </div>*/}
                            <HalfCircleSpinner color="#344abe" size={40} style={{ margin: `15px auto` }}></HalfCircleSpinner>
                            <div><b>{`${(msg) ? msg : "Cargando data..."}`}</b></div>
                        </span>


                    </div>
                    {children}
                </div>
            </div>

        </>
    )

}