import * as GQL from './_gql'
import {AuthModel } from './_modelos'
import Client from '../../graphql/client'


// Server should return AuthModel
export function login(email: string, password: string) {
    return Client.mutate<AuthModel>(GQL._Login(email, password))
}

export function getUserByToken(accessToken: string) {
    return Client.query(GQL._GetUserByAccessToken(accessToken));
  }