import {createSlice} from "@reduxjs/toolkit";

const initialInstrumentosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  instrumentoForEdit: undefined,
  lastError: null,
  queryParams: undefined
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const instrumentosSlice = createSlice({
  name: "instrumentos",
  initialState: initialInstrumentosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProductById
    instrumentoFetched: (state, action) => {
      state.actionsLoading = false;
      state.instrumentoForEdit = action.payload.instrumentoForEdit;
      state.error = null;
    },
    // findProducts
    instrumentosFetched: (state, action) => {
      const { queryParams, totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.queryParams = queryParams;
    },
    // createProduct
    instrumentoCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.instrumento);
    },
    // updateProduct
    instrumentoUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.inst_id === action.payload.instrumento.inst_id) {
          return action.payload.instrumento;
        }
        return entity;
      });
    },
    // deleteProduct
    instrumentoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.inst_id !== action.payload.inst_id);
    },
    // deleteProducts
    instrumentosDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.inst_id)
      );
    },
    // productsUpdateState
    instrumentosStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(inst_id => inst_id === entity.inst_id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
