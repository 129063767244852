/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useMemo } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import Select from 'react-select'
import { useThemeMode } from '../theme-mode/ThemeModeProvider'
import { colourStyles, formatOptions } from '../../../../app/modules/utils'
import { getAllVariables } from '../../../../app/modules/configuracion/mediciones/variables/list/core/_requests'
import { useAuth } from '../../../../app/modules/auth'
import { useHeaderToolbarContext } from '../../../layout/components/header/UIContext'
import { Nav, Tab } from 'react-bootstrap'
import { getAllMonedas, getAllUnidades } from '../../../../app/modules/configuracion/mediciones/unidades/list/core/_requests'
import moment from 'moment'
import * as utils from '../../../../app/modules/utils'

const TablerosFiltro: FC = () => {
  const { currentUser } = useAuth()
  const { mode } = useThemeMode()
  const mes_0 = +moment().format('M')
  const mes_1 = (mes_0 > 1) ? (mes_0 - 1) : 12
  const mes_2 = (mes_1 > 1) ? (mes_1 - 1) : 12
  const mes_3 = (mes_2 > 1) ? (mes_2 - 1) : 12

  const HeaderToolbarContext = useHeaderToolbarContext()
  const HeaderToolbarProps = useMemo(() => {
    return {
      opciones: HeaderToolbarContext.opciones,
      dias: HeaderToolbarContext.dias,
      setDias: HeaderToolbarContext.setDias,
      mes: HeaderToolbarContext.mes,
      setMes: HeaderToolbarContext.setMes,
      agrupar: HeaderToolbarContext.agrupar,
      setAgrupar: HeaderToolbarContext.setAgrupar,
      mostrar:HeaderToolbarContext.mostrar,
      setMostrar: HeaderToolbarContext.setMostrar,
      variProps: HeaderToolbarContext.variProps,
      setVariProps: HeaderToolbarContext.setVariProps,
      unidEnergiaProps: HeaderToolbarContext.unidEnergiaProps,
      setUnidEnergiaProps: HeaderToolbarContext.setUnidEnergiaProps,
      unidPotenciaProps: HeaderToolbarContext.unidPotenciaProps,
      setUnidPotenciaProps: HeaderToolbarContext.setUnidPotenciaProps,
      unidMontoProps: HeaderToolbarContext.unidMontoProps,
      setUnidMontoProps: HeaderToolbarContext.setUnidMontoProps,
      loading: HeaderToolbarContext.loading
    };
  }, [HeaderToolbarContext]);



  useEffect(() => {
    let isCancelled = false;
    if (HeaderToolbarProps.opciones.includes('variable')) {
      getAllVariables({
        timezone: currentUser?.usua_timezone || 'UTC',
        filter: {
          condiciones: [
            { field: "vari_id", operador: "EQUAL", value: "85e2b70e-c024-4872-97ee-34e6189892f8" },
          ]
        },
        orderBy: [{ field: "unid_factor_multi_comun", type: "ASC" }]
      }).then((result: any) => {
        if (!isCancelled) {
          const data = formatOptions(result, "variables", "vari_nombre,unid_medida", "vari_id", "vari_activo2", [], "%s (%s)")
          HeaderToolbarProps.setVariProps((prev: any) => ({ ...prev, id: data[0], disabled: false, loading: false, data: data }))
        }
      })
    }
    if (HeaderToolbarContext.opciones.includes('unidadEnergia')) {
      getAllUnidades({
        timezone: currentUser?.usua_timezone || 'UTC',
        filter: {
          condiciones: [
            { field: "unid_magn_id", operador: "EQUAL", value: "03c571a2-9c80-4fa8-bbcf-0b30055dd9c3" },
          ]
        },
        orderBy: [{ field: "unid_factor_multi_comun", type: "ASC" }]
      }).then((result: any) => {
        if (!isCancelled) {
          const data = formatOptions(result, "unidades", "unid_nombre,unid_medida", "unid_id", "unid_activo2", ["unid_medida", "unid_factor_multi_comun", "unid_defecto"], '%s (%s)')
          const defecto = data.filter((item: any) => item.unid_defecto === 1)
          HeaderToolbarProps.setUnidEnergiaProps((prev: any) => ({ ...prev, id: (defecto.length > 0) ? defecto[0] : undefined, disabled: false, loading: false, data: data }))
        }
      })
    }
    if (HeaderToolbarContext.opciones.includes('unidadPotencia')) {
      getAllUnidades({
        timezone: currentUser?.usua_timezone || 'UTC',
        filter: {
          condiciones: [
            { field: "unid_magn_id", operador: "EQUAL", value: "96b26dae-fe94-4c85-b737-174907e7efd4" },
          ]
        },
        orderBy: [{ field: "unid_factor_multi_comun", type: "ASC" }]
      }).then((result: any) => {
        if (!isCancelled) {
          const data = formatOptions(result, "unidades", "unid_nombre,unid_medida", "unid_id", "unid_activo2", ["unid_medida", "unid_factor_multi_comun", "unid_defecto"], '%s (%s)')
          const defecto = data.filter((item: any) => item.unid_defecto === 1)
          HeaderToolbarProps.setUnidPotenciaProps((prev: any) => ({ ...prev, id: (defecto.length > 0) ? defecto[0] : undefined, disabled: false, loading: false, data: data }))
        }
      })
    }
    if (HeaderToolbarProps.opciones.includes('unidadMoneda')) {
      getAllMonedas({
        timezone: currentUser?.usua_timezone || 'UTC', moneda: 'CLP',
        orderBy: [{ field: "unid_factor_multi_comun", type: "ASC" }]
      }).then((result: any) => {
        if (!isCancelled) {
          const data = formatOptions(result, "monedas", "unid_nombre,unid_medida", "unid_id", "unid_activo2", ["unid_medida", "unid_factor_multi_comun", "unid_defecto"], '%s (%s)')
          const defecto = data.filter((item: any) => item.unid_defecto === 1)
          HeaderToolbarProps.setUnidMontoProps((prev: any) => ({ ...prev, id: (defecto.length > 0) ? defecto[0] : undefined, disabled: false, loading: false, data: data }))
        }
      })
    }
  }, [currentUser?.usua_timezone, HeaderToolbarProps.opciones])


  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-4'
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')` }}
      >
        <h6 className='text-white fw-bold'>Opciones de Tableros</h6>
      </div>

      {(HeaderToolbarProps.opciones.includes('variable')) &&
        <div className='row g-0 pt-5 px-5'>
          <div className="d-flex align-items-center">
            <span className='p-2' style={{ width: '80px' }}>Variable</span>
            <div className="ms-auto p-2" style={{ width: '200px' }}>
              <Select
                id='variable'
                name='variable'
                menuPortalTarget={document.body}
                styles={colourStyles(mode)}
                options={HeaderToolbarProps.variProps.data}
                isDisabled={HeaderToolbarProps.variProps.data.length === 0 || HeaderToolbarProps.variProps.disabled}
                isLoading={HeaderToolbarProps.variProps.loading}
                placeholder={(HeaderToolbarProps.variProps.loading) ? "Cargando" : "Seleccione"}
                value={HeaderToolbarProps.variProps.id}
                onChange={(e) => {
                  HeaderToolbarProps.setVariProps((prev: any) => ({ ...prev, id: e }))
                }}
              />
            </div>
          </div>
        </div>
      }

      {(HeaderToolbarProps.opciones.includes('unidadPotencia')) &&
        <div className='row g-0 pt-5 px-5'>
          <div className="d-flex align-items-center">
            <span className='p-2' style={{ width: '80px' }}>Unidad Potencia</span>
            <div className="ms-auto p-2" style={{ width: '200px' }}>
              <Select
                id='unidad'
                name='unidad'
                menuPortalTarget={document.body}
                styles={colourStyles(mode)}
                options={HeaderToolbarProps.unidPotenciaProps.data}
                isDisabled={HeaderToolbarProps.unidPotenciaProps.length === 0 || HeaderToolbarProps.unidPotenciaProps.disabled}
                isLoading={HeaderToolbarProps.unidPotenciaProps.loading}
                placeholder={(HeaderToolbarProps.unidPotenciaProps.loading) ? "Cargando" : "Seleccione"}
                value={HeaderToolbarProps.unidPotenciaProps.id}
                onChange={(e) => {
                  HeaderToolbarProps.setUnidPotenciaProps((prev: any) => ({ ...prev, id: e }))
                }}
              />
            </div>
          </div>
        </div>
      }

      {(HeaderToolbarProps.opciones.includes('unidadEnergia')) &&
        <div className='row g-0 pt-5 px-5'>
          <div className="d-flex align-items-center">
            <span className='p-2' style={{ width: '80px' }}>Unidad Energia</span>
            <div className="ms-auto p-2" style={{ width: '200px' }}>
              <Select
                id='unidad'
                name='unidad'
                menuPortalTarget={document.body}
                styles={colourStyles(mode)}
                options={HeaderToolbarProps.unidEnergiaProps.data}
                isDisabled={HeaderToolbarProps.unidEnergiaProps.length === 0 || HeaderToolbarProps.unidEnergiaProps.disabled}
                isLoading={HeaderToolbarProps.unidEnergiaProps.loading}
                placeholder={(HeaderToolbarProps.unidEnergiaProps.loading) ? "Cargando" : "Seleccione"}
                value={HeaderToolbarProps.unidEnergiaProps.id}
                onChange={(e) => {
                  HeaderToolbarProps.setUnidEnergiaProps((prev: any) => ({ ...prev, id: e }))
                }}
              />
            </div>
          </div>
        </div>
      }
      {(HeaderToolbarProps.opciones.includes('unidadMoneda')) &&
        <div className='row g-0 pt-5 px-5'>
          <div className="d-flex align-items-center">
            <span className='p-2' style={{ width: '80px' }}>Unidad Monto</span>
            <div className="ms-auto p-2" style={{ width: '200px' }}>
              <Select
                id='monto'
                name='monto'
                menuPortalTarget={document.body}
                styles={colourStyles(mode)}
                options={HeaderToolbarProps.unidMontoProps.data}
                isDisabled={HeaderToolbarProps.unidMontoProps.length === 0 || HeaderToolbarProps.unidMontoProps.disabled}
                isLoading={HeaderToolbarProps.unidMontoProps.loading}
                placeholder={(HeaderToolbarProps.unidMontoProps.loading) ? "Cargando" : "Seleccione"}
                value={HeaderToolbarProps.unidMontoProps.id}
                onChange={(e) => {
                  HeaderToolbarProps.setUnidMontoProps((prev: any) => ({ ...prev, id: e }))
                }}
              />
            </div>
          </div>
        </div>
      }
      {(HeaderToolbarProps.opciones.includes('dias')) &&
        <div className='row g-0 p-5'>
          <div className="d-flex align-items-center">
            <span className="p-2">Dias Atras</span>
            <div className="ms-auto p-2">
              <Tab.Container defaultActiveKey={HeaderToolbarProps.dias}>
                <Nav
                  as="ul"
                  onSelect={(_dias: any) => HeaderToolbarProps.setDias(_dias)}
                  className="nav nav-pills nav-pills-sm nav-dark-75"
                >
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      role="button"
                      disabled={HeaderToolbarProps.loading}
                      eventKey="7"
                      className={`nav-link py-2 px-1 ${(HeaderToolbarProps.dias === 7) ? "active" : ""
                        }`}
                    >
                      07
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      role="button"
                      disabled={HeaderToolbarProps.loading}
                      eventKey="14"
                      className={`nav-link py-2 px-1 ${(HeaderToolbarProps.dias === 14) ? "active" : ""
                        }`}
                    >
                      14
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      role="button"
                      disabled={HeaderToolbarProps.loading}
                      eventKey="28"
                      className={`nav-link py-2 px-1 ${(HeaderToolbarProps.dias === 28) ? "active" : ""
                        }`}
                    >
                      28
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      role="button"
                      disabled={HeaderToolbarProps.loading}
                      eventKey="35"
                      className={`nav-link py-2 px-1 ${(HeaderToolbarProps.dias === 35) ? "active" : ""
                        }`}
                    >
                      35
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      role="button"
                      disabled={HeaderToolbarProps.loading}
                      eventKey="42"
                      className={`nav-link py-2 px-1 ${(HeaderToolbarProps.dias === 42) ? "active" : ""
                        }`}
                    >
                      42
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      role="button"
                      disabled={HeaderToolbarProps.loading}
                      eventKey="49"
                      className={`nav-link py-2 px-1 ${(HeaderToolbarProps.dias === 49) ? "active" : ""
                        }`}
                    >
                      49
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Tab.Container>
            </div>
          </div>
        </div>
      }
      {(HeaderToolbarProps.opciones.includes('agrupar')) &&
        <div className='row g-0 p-5'>
          <div className="d-flex align-items-center">
            <span className="p-2">Agrupar</span>
            <div className="ms-auto p-2">
              <Tab.Container defaultActiveKey={HeaderToolbarProps.agrupar}>
                <Nav
                  as="ul"
                  onSelect={(_agrupar: any) => HeaderToolbarProps.setAgrupar(_agrupar)}
                  className="nav nav-pills nav-pills-sm nav-dark-75"
                >
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      role="button"
                      //disabled={EficienciaProps.dataSegmentos.loading}
                      eventKey={'horas'}
                      className={`nav-link py-2 px-1 ${(HeaderToolbarProps.agrupar === 'horas') ? "active" : ""
                        }`}
                    >
                      Horas
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      role="button"
                      //disabled={EficienciaProps.dataSegmentos.loading}
                      eventKey={'dias'}
                      className={`nav-link py-2 px-1 ${(HeaderToolbarProps.agrupar === 'dias') ? "active" : ""
                        }`}
                    >
                      Dias
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      role="button"
                      //disabled={EficienciaProps.dataSegmentos.loading}
                      eventKey={'semanas'}
                      className={`nav-link py-2 px-1 ${(HeaderToolbarProps.mes === 'semanas') ? "active" : ""
                        }`}
                    >
                      Semanas
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Tab.Container>
            </div>
          </div>
        </div>
      }
      {(HeaderToolbarProps.opciones.includes('mostrar')) &&
        <div className='row g-0 p-5'>
          <div className="d-flex align-items-center">
            <span className="p-2">Mostrar</span>
            <div className="ms-auto p-2">
              <Tab.Container defaultActiveKey={HeaderToolbarProps.mostrar}>
                <Nav
                  as="ul"
                  onSelect={(_mostrar: any) => HeaderToolbarProps.setMostrar(_mostrar)}
                  className="nav nav-pills nav-pills-sm nav-dark-75"
                >
                  <Nav.Item className="nav-item" as="li">
                  <Nav.Link
                      role="button"
                      //disabled={EficienciaProps.dataSegmentos.loading}
                      eventKey={'potencia'}
                      className={`nav-link py-2 px-1 ${(HeaderToolbarProps.mostrar === 'potencia') ? "active" : ""
                        }`}
                    >
                      Potencia
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      role="button"
                      //disabled={EficienciaProps.dataSegmentos.loading}
                      eventKey={'energia'}
                      className={`nav-link py-2 px-1 ${(HeaderToolbarProps.mostrar === 'energia') ? "active" : ""
                        }`}
                    >
                      Energia
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      role="button"
                      //disabled={EficienciaProps.dataSegmentos.loading}
                      eventKey={'dinero'}
                      className={`nav-link py-2 px-1 ${(HeaderToolbarProps.mostrar === 'dinero') ? "active" : ""
                        }`}
                    >
                      Monto
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Tab.Container>
            </div>
          </div>
        </div>
      }
      {(HeaderToolbarProps.opciones.includes('meses')) &&
        <div className='row g-0 p-5'>
          <div className="d-flex align-items-center">
            <span className="p-2">Mes</span>
            <div className="ms-auto p-2">
              <Tab.Container defaultActiveKey={HeaderToolbarProps.mes}>
                <Nav
                  as="ul"
                  onSelect={(_mes: any) => HeaderToolbarProps.setMes(_mes)}
                  className="nav nav-pills nav-pills-sm nav-dark-75"
                >
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      role="button"
                      //disabled={EficienciaProps.dataSegmentos.loading}
                      eventKey={mes_3}
                      className={`nav-link py-2 px-1 ${(HeaderToolbarProps.mes === mes_3) ? "active" : ""
                        }`}
                    >
                      {utils.meses.c.split('.')[mes_3 - 1]}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      role="button"
                      //disabled={EficienciaProps.dataSegmentos.loading}
                      eventKey={mes_2}
                      className={`nav-link py-2 px-1 ${(HeaderToolbarProps.mes === mes_2) ? "active" : ""
                        }`}
                    >
                      {utils.meses.c.split('.')[mes_2 - 1]}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      role="button"
                      //disabled={EficienciaProps.dataSegmentos.loading}
                      eventKey={mes_1}
                      className={`nav-link py-2 px-1 ${(HeaderToolbarProps.mes === mes_1) ? "active" : ""
                        }`}
                    >
                      {utils.meses.c.split('.')[mes_1 - 1]}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      role="button"
                      //disabled={EficienciaProps.dataSegmentos.loading}
                      eventKey={mes_0}
                      className={`nav-link py-2 px-1 ${(HeaderToolbarProps.mes === mes_0) ? "active" : ""
                        }`}
                    >
                      {utils.meses.c.split('.')[mes_0 - 1]}
                    </Nav.Link>
                  </Nav.Item>

                </Nav>
              </Tab.Container>
            </div>
          </div>
        </div>
      }
    </div>
  )
}
export { TablerosFiltro }
