import { useMemo,useRef, useEffect, useState } from 'react'
import { useHeaderToolbarContext } from '../../../_metronic/layout/components/header/UIContext'
import { useThemeMode } from '../../../_metronic/partials'
import { ListLoading } from "../../modules/utils/components/loading/ListLoading";
import * as utils from "../../modules/utils/";
import moment from "moment";
import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import "chartjs-adapter-moment"
import { getVariableHistorica } from '../../modules/tableros/_redux/eficiencias/core/Crud';
Chart.register(...registerables);
Chart.register(zoomPlugin);

var c: any = []

const formatearSeries = (paleta:any,series: any, colores: any) => {

    return series.map((serie: any, index: number) => {
        let color = (colores[index]) ? colores[index] : utils.newColors.getColor(c).hex;
        c.push(color)
        return {
            label: serie.label,
            data: serie.valores,
            borderWidth: 1,
            fill: false,
            pointRadius: 0,
            pointHoverRadius: 0,
            //order:(series.length-1) - index,
            //borderColor: (colores[index]) ? colores[index] : utils.Colors.getColor([]).rgb,
            //backgroundColor: (colores[index]) ? colores[index] : utils.Colors.getColor([]).rgb
            borderColor: color,
            backgroundColor: utils.hexToRgbA(color, 0.5),//color


        }
    })
}


const GraficoVariableHistorica = ({ dataHistorica, theme, colores }: any) => {
    const chartRef = useRef()
    const colorText:string = (theme==='dark')?'white':'#6e6f6f'
    const colorGrid:string = (theme==='dark')?utils.hexToRgbA('#ffffff',0.1):utils.hexToRgbA('#6e6f6f',0.1)
    
    useEffect(()=>{
        if (chartRef.current) {
    
        }
    },[theme])
    
        return (
            <>
                {
                    //<Loading loading={dataHistorica.loading}>
                    <>
                        {dataHistorica.loading && <ListLoading />}
                        <Line
                            id="graphPotencias"
                            ref={chartRef}
                            data={{
                                datasets: formatearSeries(colores,(dataHistorica?.properties?.series) ? dataHistorica?.properties?.series : [], (dataHistorica?.properties?.colores) ? dataHistorica?.properties?.colores : [])
                            }}
                            plugins={[{
                                id: "tooltipLine",
                                afterDraw: (chart: any) => {
                                    if (chart.tooltip?._active?.length) {
                                        let x = chart.tooltip._active[0].element.x;
                                        let yAxis = chart.scales.y;
                                        let ctx = chart.ctx;
                                        ctx.save();
                                        ctx.beginPath();
                                        ctx.moveTo(x, yAxis.top);
                                        ctx.lineTo(x, yAxis.bottom);
                                        ctx.lineWidth = 1;
                                        ctx.strokeStyle = 'rgba(0, 0, 255, 0.4)';
                                        ctx.stroke();
                                        ctx.restore();
                                    }
                                    let lineas = (chart.data.datasets.length) ? chart.data.datasets[0].data.filter((item: any) => moment(item.x).format('HH:mm') === '00:00') : [];
                                    let xAxis = chart.scales.x;
                                    let yAxis = chart.scales.y;
                                    let ctx = chart.ctx;
                                    lineas.map((linea: any) => {
                                        let x = xAxis.getPixelForValue(moment(linea.x), undefined)
                                        ctx.save();
                                        ctx.beginPath();
                                        ctx.moveTo(x, yAxis.top);
                                        ctx.lineTo(x, yAxis.bottom);
                                        ctx.lineWidth = 1;
                                        ctx.strokeStyle = '#becee4';//'rgba(90, 0, 90, 0.7)';
                                        ctx.stroke();
                                        ctx.textAlign = 'left';
                                        ctx.fillStyle = colorText;
                                        ctx.fillText((ctx.canvas.clientWidth > 250) ? `${moment(linea.x).date()}` : ``, x + 3, yAxis.top + 12);
                                        ctx.restore();
                                        return null
                                    })
                                }
                            }]}
                            options={{
                                interaction: {
                                    intersect: false,
                                    mode: 'index',
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    y: {
                                        display: true,
                                        position: "left",
                                        grid:{
                                            color:colorGrid
                                        },
                                        title: {
                                            color:colorText,
                                            display: true,
                                            text: `${(dataHistorica?.properties?.variable?.vari_nombre) ? dataHistorica?.properties?.variable?.vari_nombre : ''} ${(dataHistorica?.properties?.variable?.unid_medida) ? dataHistorica?.properties?.variable?.unid_medida : ''}`
                                        },
                                        ticks: {
                                            color:colorText,
                                            callback: function (value, index, values) {
                                                return value.toLocaleString();
                                            }
                                        },
                                        beginAtZero: true,
                                        
                                    },
                                    x: {
                                        type: 'time',
                                        title: {
                                            color:colorText,
                                            display: true,
                                            text: 'Periodo'
                                        },
                                        grid:{
                                            color:colorGrid
                                        },
                                        time: {
                                            tooltipFormat: 'yyyy-MM-DD HH:mm',
                                            displayFormats: {
                                                millisecond: 'HH:mm:ss.SSS',
                                                second: 'HH:mm:ss',
                                                minute: 'HH:mm',
                                                hour: 'HH:mm',
                                                day: 'yyyy-MM-DD'
                                            }
                                        },
                                        ticks:{
                                            color:colorText,
                                        }
                                    }
                                },
                                plugins: {
                                    title: {
                                        font: {
                                            size: 20,
                                        },
                                        //display: (propsWg?.title !== undefined),
                                        //text: (propsWg?.title) ? propsWg?.title : ""
                                    },
                                    /*zoom: {
                                        zoom: {
                                            drag: {
                                                enabled: true
                                            },
                                            mode: 'xy',
                                        },
                                        pan: {
                                            enabled: true,
                                            modifierKey: 'ctrl',
                                        },
                                    },*/
                                    legend:{
                                        labels:{
                                            color:colorText
                                        },
                                        display:false
                                    },
                                    tooltip: {
                                        callbacks: {
                                            title: (tooltipItem: any) => {
                                                return (
                                                    `${utils.dias.c.split('.')[moment(tooltipItem[0].label).day()]} ${tooltipItem[0].label}`
                                                )
                                            },
                                            label: (tooltipItem: any) => {
                                                return `${tooltipItem.dataset.label}: ${parseFloat(tooltipItem.raw.y).toLocaleString("es", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${dataHistorica?.properties?.variable?.unid_medida}`
                                            },
                                        },
                                        backgroundColor:utils.hexToRgbA('#30475E', 0.9)
                                    }
                                }
                            }}
                        />
                    </>
                }
    
            </>
        )
    
    }

const WidgetHistorico = ({ instrumento,color}: any) => {


    var colores:any = []
    const {mode } = useThemeMode()
    const [dataHistorica, setDataHistorica] = useState<any>({ loading: false, properties:{}})
    const HeaderToolbarContext = useHeaderToolbarContext();
    const HeaderToolbarProps = useMemo(() => {
      return {
        dias: HeaderToolbarContext.dias,
        loading: HeaderToolbarContext.loading,
        setLoading: HeaderToolbarContext.setLoading,
        variProps: HeaderToolbarContext.variProps
      };
    }, [HeaderToolbarContext]);


    useEffect(()=>{
        let isCancelled = false;
        setDataHistorica((prev: any) => ({ ...prev, loading: true }))
        colores = []
        getVariableHistorica({
            "edif_id": instrumento.edif_id,
            "lista_inst_id": [instrumento?.inst_id],
            "opc_titulo": 2,
            "opc_subtitulo": 1,
            "vari_id": HeaderToolbarProps.variProps.id?.value,
            "opc_label": 1,
            "intervalo": 900,
            "lista_color":[color],
            "dias_atras": HeaderToolbarProps.dias
        }
        ).then((result: any) => {
            if (!isCancelled) {
                setDataHistorica((prev: any) => ({ ...prev, loading: false, properties: ((result?.data?.widgVariableHistorica !== null) && (result?.data?.widgVariableHistorica[0]?.get_variable_historica)) ? result?.data?.widgVariableHistorica[0]?.get_variable_historica : {} }))
            }
        })
        return () => {
            isCancelled = true;
        };
    },[HeaderToolbarProps.dias,HeaderToolbarProps.variProps.id?.value,instrumento])


    return (
        <>
            <div className="col col-lg-6">
                <div className={`card card-xl-stretch mb-xl-6  h-100`}>
                    <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>{(dataHistorica?.properties?.series?.length>0) && dataHistorica?.properties?.series[0].label}</span>

                        <span className='text-muted fw-semibold fs-7'>{(dataHistorica?.properties?.series?.length>0) && dataHistorica?.properties?.series[0].equi_nombre}</span>
                    </h3>
                    </div>
                    <div className='card-body mb-1 py-1'>
                        <div style={{ margin: "auto", height: "300px" }}>
                            <GraficoVariableHistorica dataHistorica={dataHistorica} theme={mode} colores={colores}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const WidgetsPage = ({ props}: any) => {
    var colores:any = ["#2192FF","#82ca9d","#FBCB0A","#FF4A4A","#F1CA89","#40DFEF","#B8B5FF"]
    return (
        <>
            <div className='row g-5 g-xl-8 mb-3'>
                {(props?.id?.hist_inst_id) && props?.id?.hist_inst_id.map((inst: any, index:any) => {
                    return <WidgetHistorico key={inst.inst_id} instrumento={inst} color={colores[index]}/>
                })}
            </div>
        </>
    )

}

export default WidgetsPage