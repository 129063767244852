/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useMemo, useState } from 'react'
import { useLayout } from '../../core'
//import {KTSVG} from '../../../helpers'
import { DefaultTitle } from './page-title/DefaultTitle'
import { useThemeMode } from '../../../partials'
import { getAllEdificios } from '../../../../app/modules/configuracion/mediciones/edificios/list/core/_requests'
import { getAllEficienciasPermitidas } from '../../../../app/modules/tableros/_redux/eficiencias/core/Crud'
import { getAllUnidades, getAllMonedas } from '../../../../app/modules/configuracion/mediciones/unidades/list/core/_requests'
import { useHeaderToolbarContext } from './UIContext'
import { formatOptions, colourStyles } from '../../../../app/modules/utils'
import { useAuth } from '../../../../app/modules/auth'
import Toggle from 'react-styled-toggle'
import Select from 'react-select';
import { Nav, Tab } from "react-bootstrap";
import { getAllHistoricosPermitidos } from '../../../../app/modules/tableros/_redux/historicos/Crud'
import { getAllVariables } from '../../../../app/modules/configuracion/mediciones/variables/list/core/_requests'
import { KTSVG } from '../../../helpers'
import clsx from 'clsx'
import { getAllTableros } from '../../../../app/modules/tableros/principal/dashboard2/core/_requests'
import { isEmpty } from 'lodash-es'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const HeaderToolbar = () => {
  const { classes } = useLayout()
  const { currentUser } = useAuth()
  const [label, setLabel] = useState('')
  const { mode } = useThemeMode()

  const HeaderToolbarContext = useHeaderToolbarContext();
  const HeaderToolbarProps = useMemo(() => {
    return {
      dias: HeaderToolbarContext.dias,
      setDias: HeaderToolbarContext.setDias,
      opcion: HeaderToolbarContext.opcion,
      unidEnergiaProps: HeaderToolbarContext.unidEnergiaProps,
      setUnidEnergiaProps: HeaderToolbarContext.setUnidEnergiaProps,
      unidMontoProps: HeaderToolbarContext.unidMontoProps,
      setUnidMontoProps: HeaderToolbarContext.setUnidMontoProps,
      energia: HeaderToolbarContext.energia,
      setEnergia: HeaderToolbarContext.setEnergia,
      props: HeaderToolbarContext.props,
      setProps: HeaderToolbarContext.setProps,
      variProps: HeaderToolbarContext.variProps,
      setVariProps: HeaderToolbarContext.setVariProps,
      loading: HeaderToolbarContext.loading,


      setOpciones: HeaderToolbarContext.setOpciones,
      tablProps: HeaderToolbarContext.tablProps,
      setTablProps: HeaderToolbarContext.setTablProps,
      edifProps: HeaderToolbarContext.edifProps,
      setEdifProps: HeaderToolbarContext.setEdifProps
    };
  }, [HeaderToolbarContext]);

  useEffect(() => {
    let isCancelled = false;
    //HeaderToolbarProps.setEdifProps((prev: any) => ({ ...prev, disabled: true, loading: true, data: [] }))
    /*HeaderToolbarProps.setProps((prev: any) => {
      let nuevo = [...prev];
      nuevo[HeaderToolbarProps.opcion] = { ...nuevo[HeaderToolbarProps.opcion], disabled: true, loading: true, data: [] }
      return nuevo;
    })*/
    switch (HeaderToolbarProps.opcion) {
      /*case 0:
        setLabel('Edificios');
        getAllEdificios({
          timezone: currentUser?.usua_timezone || 'UTC',
          filter: {
            condiciones: [
              { field: "edif_enti_id", operador: "IN", value: `select perm_enti_id from mediciones.v_permisos_all where (perm_usua_id='${currentUser?.usua_id}') and (perm_activo=1)` }
            ]
          }
        }).then((result: any) => {
          if (!isCancelled) {

            const data = formatOptions(result, "edificios", "edif_nombre", "edif_id", "edif_activo2", ["edif_prefijo"], "%s");
            HeaderToolbarProps.setProps((prev: any) => {
              let nuevo = [...prev];
              nuevo[HeaderToolbarProps.opcion] = { ...nuevo[HeaderToolbarProps.opcion], id: data[0], disabled: false, loading: false, data: data }
              return nuevo;
            })
          }
        })
        break;*/
      case 1:
        setLabel('Tableros');
        getAllEficienciasPermitidas({
          filter: {
            condiciones: [
              { field: "perm_usua_id", operador: "EQUAL", value: currentUser?.usua_id },
            ]
          },
          orderBy: [{ field: "efic_creacion", type: "ASC" }]
        }).then((result: any) => {
          if (!isCancelled) {
            const data = formatOptions(result, "eficienciasPermitidas", "efic_nombre", "efic_id", "efic_activo2", ["efic_edif_id", "efic_principal_inst_id", "efic_principal_potencia_vari_id", "efic_lista_inst_id", "efic_dias_atras"], "%s");
            const defecto = data.filter((item: any) => item.perm_defecto === 1)
            //HeaderToolbarProps.setEdifProps((prev: any) => ({ ...prev, id: data[0], disabled: false, loading: false, data: data }))
            HeaderToolbarProps.setProps((prev: any) => {
              let nuevo = [...prev];
              nuevo[HeaderToolbarProps.opcion] = { ...nuevo[HeaderToolbarProps.opcion], id: (defecto.length > 0) ? defecto[0] : (data.length > 0) ? data[0] : undefined, disabled: false, loading: false, data: data }
              return nuevo;
            })
          }
        })
        break;
      case 2:
        setLabel('Tableros');
        getAllHistoricosPermitidos({
          filter: {
            condiciones: [
              { field: "perm_usua_id", operador: "EQUAL", value: currentUser?.usua_id },
            ]
          },
          orderBy: [{ field: "hist_creacion", type: "ASC" }]
        }).then((result: any) => {
          if (!isCancelled) {
            const data = formatOptions(result, "historicosPermitidos", "hist_nombre", "hist_id", "hist_activo2", ["hist_vari_id", "hist_inst_id", "hist_dias_atras"], "%s");
            const defecto = data.filter((item: any) => item.perm_defecto === 1)
            //HeaderToolbarProps.setEdifProps((prev: any) => ({ ...prev, id: data[0], disabled: false, loading: false, data: data }))
            HeaderToolbarProps.setProps((prev: any) => {
              let nuevo = [...prev];
              nuevo[HeaderToolbarProps.opcion] = { ...nuevo[HeaderToolbarProps.opcion], id: (defecto.length > 0) ? defecto[0] : (data.length > 0) ? data[0] : undefined, disabled: false, loading: false, data: data }
              return nuevo;
            })
          }
        });
        /*getAllVariables({
          timezone: currentUser?.usua_timezone || 'UTC',
          filter: {
            condiciones: [
              { field: "vari_id", operador: "EQUAL", value: "85e2b70e-c024-4872-97ee-34e6189892f8" },
            ]
          },
          orderBy: [{ field: "unid_factor_multi_comun", type: "ASC" }]
        }).then((result: any) => {
          if (!isCancelled) {
            const data = formatOptions(result, "variables", "vari_nombre,unid_medida", "vari_id", "vari_activo2", [], "%s (%s)")
            HeaderToolbarProps.setVariProps((prev: any) => ({ ...prev, id: undefined, disabled: false, loading: false, data: data }))
          }
        })*/
        break;
      case 0:
        HeaderToolbarProps.setEdifProps((prev: any) => ({ ...prev, id: undefined, disabled: true, loading: true, data: [] }))
        getAllEdificios({
          timezone: currentUser?.usua_timezone || 'UTC',
          filter: {
            condiciones: [
              { field: "edif_enti_id", operador: "IN", value: `select perm_enti_id from mediciones.v_permisos_all where (perm_usua_id='${currentUser?.usua_id}') and (perm_activo=1)` }
            ]
          }
        }).then((result: any) => {
          if (!isCancelled) {
            const data = formatOptions(result, "edificios", "edif_nombre", "edif_id", "edif_activo2", ["edif_prefijo"], "%s");
            HeaderToolbarProps.setEdifProps((prev: any) => ({ ...prev, id: (data.length > 0) ? data[0] : {}, disabled: false, loading: false, data: data }))
          }
        })
        break;
      case 3:
        HeaderToolbarProps.setTablProps((prev: any) => ({ ...prev, /*id: undefined,*/ disabled: true, loading: true, data: [] }))
        getAllTableros({
          timezone: currentUser?.usua_timezone || 'UTC',
          filter: {
            condiciones: [
              { field: "tabl_usua_id", operador: "ANYUUID", value: currentUser?.usua_id },
              { field: "tabl_activo", operador: "EQUAL", value: "1" }
            ]
          }
        }).then((result: any) => {
          const data = formatOptions(result, "tableros", "tabl_nombre", "tabl_id", "tabl_activo2", ["tabl_configuracion", "tabl_usua_info"], "%s");
          let seleccion: any = {}
          if (data.length > 0) {
            let defecto = data.filter((tablero: any) => tablero.tabl_usua_info.filter((permiso: any) => (permiso.usua_id === currentUser?.usua_id && permiso.usua_defecto === true)).length > 0)
            if (HeaderToolbarProps.tablProps?.id?.value) {
              seleccion = HeaderToolbarProps.tablProps?.id
            }

            if (isEmpty(seleccion)) {
              if (defecto.length > 0) {
                seleccion = defecto[0]
              }
              else {
                seleccion = data[0]
              }
            }
          }
          HeaderToolbarProps.setTablProps((prev: any) => ({ ...prev, id: seleccion, disabled: false, loading: false, data: data }))
        })
        break;
    }
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.usua_id, HeaderToolbarProps.opcion]) //Si cambio de Usuario

  useEffect(() => {
    let isCancelled = false;
    if (HeaderToolbarProps.opcion === 1) {
      HeaderToolbarProps.setUnidEnergiaProps((prev: any) => ({ ...prev, disabled: true, loading: true, data: [] }))
      getAllUnidades({
        timezone: currentUser?.usua_timezone || 'UTC',
        filter: {
          condiciones: [
            { field: "unid_id", operador: "EQUAL", value: "85e2b70e-c024-4872-97ee-34e6189892f8" },
          ]
        },
        orderBy: [{ field: "unid_factor_multi_comun", type: "ASC" }]
      }).then((result: any) => {
        if (!isCancelled) {
          const data = formatOptions(result, "unidades", "unid_nombre,unid_medida", "unid_id", "unid_activo2", ["unid_medida", "unid_factor_multi_comun", "unid_defecto"], '%s (%s)')
          const defecto = data.filter((item: any) => item.unid_defecto === 1)
          HeaderToolbarProps.setUnidEnergiaProps((prev: any) => ({ ...prev, id: (defecto.length > 0) ? defecto[0] : undefined, disabled: false, loading: false, data: data }))
        }
      })
      return () => {
        isCancelled = true;
      };
    }
  }, [HeaderToolbarProps.opcion])

  useEffect(() => {
    let isCancelled = false;
    if (HeaderToolbarProps.opcion === 1) {
      HeaderToolbarProps.setUnidMontoProps((prev: any) => ({ ...prev, disabled: true, loading: true, data: [] }))
      getAllMonedas({
        timezone: currentUser?.usua_timezone || 'UTC', moneda: 'CLP',
        orderBy: [{ field: "unid_factor_multi_comun", type: "ASC" }]
      }).then((result: any) => {
        if (!isCancelled) {
          const data = formatOptions(result, "monedas", "unid_nombre,unid_medida", "unid_id", "unid_activo2", ["unid_medida", "unid_factor_multi_comun", "unid_defecto"], '%s (%s)')
          const defecto = data.filter((item: any) => item.unid_defecto === 1)
          HeaderToolbarProps.setUnidMontoProps((prev: any) => ({ ...prev, id: (defecto.length > 0) ? defecto[0] : undefined, disabled: false, loading: false, data: data }))
        }
      })
      return () => {
        isCancelled = true;
      };
    }
  }, [HeaderToolbarProps.opcion])

  useEffect(() => {
    if ((HeaderToolbarProps.opcion === 2) && (HeaderToolbarProps.props[HeaderToolbarProps.opcion].id)) {
      HeaderToolbarProps.setVariProps((prev: any) => ({ ...prev, id: prev.data.filter((item: any) => item.value === HeaderToolbarProps.props[HeaderToolbarProps.opcion].id.hist_vari_id)[0] }))
    }
  }, [HeaderToolbarProps.opcion, HeaderToolbarProps.variProps.data])


  useEffect(() => {
    HeaderToolbarProps.setOpciones(HeaderToolbarProps.tablProps.id?.tabl_configuracion?.filtros || [])
  }, [HeaderToolbarProps.tablProps?.id])




  return (
    <div className='toolbar d-flex align-items-stretch'>
      {/* begin::Toolbar container */}
      <div
        className={`${classes.headerContainer.join(
          ' '
        )} py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between`}
      >
        <DefaultTitle />
        <div className='d-flex align-items-stretch overflow-auto pt-3 pt-lg-0'>
          {/* begin::Action wrapper */}
          {(HeaderToolbarProps.opcion === 1) &&
            <div className='d-flex align-items-center'>
              {/* begin::Label */}
              <span className='fs-7 fw-bolder text-gray-700 pe-4 text-nowrap d-none d-xxl-block'>
                Mostrar:
              </span>
              {/* end::Label */}
              <div style={{ width: '200px' }}>
                <Toggle

                  width={40}
                  height={22}
                  sliderWidth={14}
                  sliderHeight={14}
                  translate={19}
                  name={"mostrar"}
                  labelLeft={"Monto"}
                  labelRight={"Energia"}
                  disabled={false}
                  checked={HeaderToolbarProps.energia}
                  value={"1"}
                  onChange={() => { HeaderToolbarProps.setEnergia((prev: boolean) => !prev) }}
                />
              </div>
              <div className='bullet bg-secondary h-35px w-1px mx-5'></div>
            </div>
          }
          {((HeaderToolbarProps.opcion === 1) && (HeaderToolbarProps.energia)) &&
            <div className='d-flex align-items-center'>
              {/* begin::Label */}
              <span className='fs-7 fw-bolder text-gray-700 pe-4 text-nowrap d-none d-xxl-block'>
                Unidades:
              </span>
              {/* end::Label */}
              <div style={{ width: '200px' }}>
                <Select
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999, color: '#33112C' }) }}
                  //options={HeaderToolbarProps.edifProps.data}
                  options={HeaderToolbarProps.unidEnergiaProps.data}
                  isDisabled={HeaderToolbarProps.unidEnergiaProps.data.length === 0 || HeaderToolbarProps.unidEnergiaProps.disabled}
                  isLoading={HeaderToolbarProps.unidEnergiaProps.loading}
                  placeholder={(HeaderToolbarProps.unidEnergiaProps.loading) ? "Cargando" : "Seleccione"}
                  value={HeaderToolbarProps.unidEnergiaProps.id}
                  onChange={(e) => {
                    HeaderToolbarProps.setUnidEnergiaProps((prev: any) => ({ ...prev, id: e }))
                  }}
                />
              </div>
              <div className='bullet bg-secondary h-35px w-1px mx-5'></div>
            </div>
          }
          {((HeaderToolbarProps.opcion === 1) && (!HeaderToolbarProps.energia)) &&
            <div className='d-flex align-items-center'>
              {/* begin::Label */}
              <span className='fs-7 fw-bolder text-gray-700 pe-4 text-nowrap d-none d-xxl-block'>
                Unidades:
              </span>
              {/* end::Label */}
              <div style={{ width: '200px' }}>
                <Select
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999, color: '#33112C' }) }}
                  //options={HeaderToolbarProps.edifProps.data}
                  options={HeaderToolbarProps.unidMontoProps.data}
                  isDisabled={HeaderToolbarProps.unidMontoProps.data.length === 0 || HeaderToolbarProps.unidMontoProps.disabled}
                  isLoading={HeaderToolbarProps.unidMontoProps.loading}
                  placeholder={(HeaderToolbarProps.unidMontoProps.loading) ? "Cargando" : "Seleccione"}
                  value={HeaderToolbarProps.unidMontoProps.id}
                  onChange={(e) => {
                    HeaderToolbarProps.setUnidMontoProps((prev: any) => ({ ...prev, id: e }))
                  }}
                />
              </div>
              <div className='bullet bg-secondary h-35px w-1px mx-5'></div>
            </div>
          }
          {/*(HeaderToolbarProps.opcion === 2) &&
            <>
              <div className='d-flex align-items-center'>
                <span className='fs-7 fw-bolder text-gray-700 pe-4 text-nowrap d-none d-xxl-block'>
                  Variable:
                </span>
                <div style={{ width: '200px' }}>
                  <Select
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999, color: '#33112C' }) }}
                    options={HeaderToolbarProps.variProps.data}
                    isDisabled={HeaderToolbarProps.variProps.data.length === 0 || HeaderToolbarProps.variProps.disabled}
                    isLoading={HeaderToolbarProps.variProps.loading}
                    placeholder={(HeaderToolbarProps.variProps.loading) ? "Cargando" : "Seleccione"}
                    value={HeaderToolbarProps.variProps.id}
                    onChange={(e) => {
                      HeaderToolbarProps.setVariProps((prev: any) => ({ ...prev, id: e }))
                    }}
                  />
                </div>
                <div className='bullet bg-secondary h-35px w-1px mx-5'></div>
              </div>

              <div className='d-flex align-items-center'>
                <span className='fs-7 fw-bolder text-gray-700 pe-4 text-nowrap d-none d-xxl-block'>
                  Dias:
                </span>
                <div >
                  <Tab.Container defaultActiveKey={HeaderToolbarProps.dias}>
                    <Nav
                      as="ul"
                      onSelect={(_dias: any) => HeaderToolbarProps.setDias(_dias)}
                      className="nav nav-pills nav-pills-sm nav-dark-75"
                    >
                      <Nav.Item className="nav-item" as="li">
                        <Nav.Link
                          role="button"
                          disabled={HeaderToolbarProps.loading}
                          eventKey="7"
                          className={`nav-link py-2 px-4 ${(HeaderToolbarProps.dias === 7) ? "active" : ""
                            }`}
                        >
                          07
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav-item" as="li">
                        <Nav.Link
                          role="button"
                          disabled={HeaderToolbarProps.loading}
                          eventKey="15"
                          className={`nav-link py-2 px-4 ${(HeaderToolbarProps.dias === 15) ? "active" : ""
                            }`}
                        >
                          15
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav-item" as="li">
                        <Nav.Link
                          role="button"
                          disabled={HeaderToolbarProps.loading}
                          eventKey="30"
                          className={`nav-link py-2 px-4 ${(HeaderToolbarProps.dias === 30) ? "active" : ""
                            }`}
                        >
                          30
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Tab.Container>
                </div>
                <div className='bullet bg-secondary h-35px w-1px mx-5'></div>
              </div>
            </>
                          */}

          {(HeaderToolbarProps.opcion === 0) &&
            <div className='d-flex align-items-center'>
              {/* begin::Label */}
              <span className='fs-7 fw-bolder text-gray-700 pe-4 text-nowrap d-none d-xxl-block'>
                Edificios:
              </span>
              {/* end::Label */}
              <div style={{ width: '200px' }}>
                <Select
                  menuPortalTarget={document.body}
                  styles={colourStyles(mode)}
                  //options={HeaderToolbarProps.edifProps.data}
                  options={HeaderToolbarProps.edifProps.data}
                  isDisabled={HeaderToolbarProps.edifProps.data.length === 0 || HeaderToolbarProps.edifProps.disabled}
                  isLoading={HeaderToolbarProps.edifProps.loading}
                  placeholder={(HeaderToolbarProps.edifProps.loading) ? "Cargando" : "Seleccione"}
                  value={HeaderToolbarProps.edifProps.id}
                  onChange={(e) => {
                    //HeaderToolbarProps.setEdifProps((prev: any) => ({ ...prev, id: e }))
                    HeaderToolbarProps.setEdifProps((prev: any) => ({ ...prev, id: e }))
                  }}
                />
              </div>
              <div className='bullet bg-secondary h-35px w-1px mx-5'></div>
            </div>
          }
          {(HeaderToolbarProps.opcion === 3) &&
            <div className='d-flex align-items-center'>
              {/* begin::Label */}
              <span className='fs-7 fw-bolder text-gray-700 pe-4 text-nowrap d-none d-xxl-block'>
                Tableros:
              </span>
              {/* end::Label */}
              <div style={{ width: '200px' }}>
                <Select
                  menuPortalTarget={document.body}
                  styles={colourStyles(mode)}
                  //options={HeaderToolbarProps.edifProps.data}
                  options={HeaderToolbarProps.tablProps.data}
                  isDisabled={HeaderToolbarProps.tablProps.data.length === 0 || HeaderToolbarProps.tablProps.disabled}
                  isLoading={HeaderToolbarProps.tablProps.loading}
                  placeholder={(HeaderToolbarProps.tablProps.loading) ? "Cargando" : "Seleccione"}
                  value={HeaderToolbarProps.tablProps.id}
                  onChange={(e) => {
                    //HeaderToolbarProps.setEdifProps((prev: any) => ({ ...prev, id: e }))
                    HeaderToolbarProps.setTablProps((prev: any) => ({ ...prev, id: e }))
                  }}
                />
              </div>
              <div className='bullet bg-secondary h-35px w-1px mx-5'></div>
            </div>
          }

        </div>
      </div>
    </div>
  )
}

export { HeaderToolbar }
