export function descendingComparator<Consumo>(a: any, b: any, orderBy: keyof any) {
    const order = (orderBy !== "equi_nombre") ? orderBy : "equi_numero" as keyof any;

    if (b[order] < a[order]) {
        return -1;
    }
    if (b[order] > a[order]) {
        return 1;
    }
    return 0;
}

export function getComparator<Key extends keyof any>(
    order: string,
    orderBy: Key
): (a: any, b: any) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export {}

