const QUERIES = {
  USERS_LIST: 'users-list',
  PAISES_LIST: 'paises-list',
  CARGOS_LIST:'cargos-list',
  CALCULOS_LIST:'calculos-list',
  ZONAS_LIST:'zonas-list',
  REGIONES_LIST:'regiones-list',
  CIUDADES_LIST:'ciudades-list',
  FRANQUICIAS_LIST:'franquicias-list',
  PROPIETARIOS_LIST:'propietarios-list',
  PISOS_LIST:'pisos-list',
  AREAS_LIST:'areas-list',
  MAGNITUDES_LIST:'magnitudes-list',
  UNIDADES_LIST:'unidades-list',
  VARIABLES_LIST:'variables-list',
  SERVICIOS_LIST:'servicios-list',
  EDIFICIOS_LIST:'edificios-list',
  DATABASES_LIST:'databases-list',
  DISPOSITIVOS_LIST:'dispositivos-list',
  PROVEEDORES_LIST:'proveedores-list',
  INSTRUMENTOS_LIST:'instrumentos-list',
  ESPACIOS_LIST:'espacios-list',
  UBICACIONES_LIST:'ubicaciones-list',
  MODULOS_LIST:'modulos-list',
  ROLES_LIST:'roles-list',
  EMPRESAS_LIST:'empresas-list',
  PERSONAS_LIST:'personas-list',
  PUESTOS_LIST:'puestos-list',
  EMPLEOS_LIST:'empleos-list',
  USUARIOS_LIST:'usuarios-list',
  USUARIOSPERSONAS_LIST:'usuariospersonas-list',
  USUARIOSROLES_LIST:'usuariosroles-list',
  PERMISOS_LIST:'permisos-list',
  CONTRATOS_LIST:'contratos-list',
  CONTRATOSTARIFAS_LIST:'contratostarifas-list',
  TARIFAS_LIST:'tarifas-list',
  TARIFASEDIFICIOS_LIST:'tarifasedificios-list',
  ALARMAS_LIST:'alarmas-list',
  TELEGRAMCANALES_LIST:'alarmas-list',
}

export {QUERIES}
