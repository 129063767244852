/* eslint-disable react/jsx-no-target-blank */
//import { useIntl } from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { useAuth } from '../../../../app/modules/auth'
import React from 'react'

export function AsideMenuMain() {
  const { currentUser } = useAuth()


  const dynamicsort = (property: string, order: string) => {
    var sort_order = 1;
    if (order === "desc") {
      sort_order = -1;
    }
    return function (a: any, b: any) {
      // a should come before b in the sorted order
      if (a[property] < b[property]) {
        return -1 * sort_order;
        // a should come after b in the sorted order
      } else if (a[property] > b[property]) {
        return 1 * sort_order;
        // a and b are the same
      } else {
        return 0 * sort_order;
      }
    }
  }

  const FixedMenu = ({ item }: any) => {
    return (
      <div key={`FM_${item.pemo_modu_id}`} className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{item.modu_nombre}</span>
        </div>
      </div>
    )
  }

  const SubMenu =({items,itemsFiltered}:any) =>{
    return (itemsFiltered) ? itemsFiltered.sort(dynamicsort("modu_posicion", "asc")).map((item:any) => {
      let childrens = items.filter((itemx:any) => itemx.modu_padre_id === item.pemo_modu_id);
      return <React.Fragment key={`RF_${item.pemo_modu_id}`}>
        {(childrens.length > 0)
        ?<AsideMenuItemWithSub
        to={`/${item.modu_ruta}`}
        title={item.modu_nombre}
        icon={item.modu_icono}
        >
          <SubMenu items={items} itemsFiltered={childrens} />
        </AsideMenuItemWithSub>  
        :<AsideMenuItem to={`/${item.modu_ruta}`} title={item.modu_nombre} icon={item.modu_icono} />}
      </React.Fragment>
    }): <></>
  }


  const MakeMenu = ({ items, padre }: any) => {
    return (items) ? items.filter((item: any) => item.modu_padre_id === padre).sort(dynamicsort("modu_posicion", "asc")).map((item: any) => {
      let childrens = items.filter((itemx: any) => itemx.modu_padre_id === item.pemo_modu_id);
      return <React.Fragment key={`RF_${item.pemo_modu_id}`}>
        <FixedMenu key={`HM_${item.pemo_modu_id}`} item={item} />
        {(childrens.length > 0) && (
          <SubMenu items={items} itemsFiltered={childrens} />
        )}
      </React.Fragment>
    }) : <></>
  }

  return (
    <>
      <MakeMenu items={currentUser?.modulos.data} padre={null} />
    </>
  )
}
