import { Unidad, UnidadesQueryResponse } from './_models'
import { getPagination } from '../../../../../utils'
import Client from '../../../../../graphql/client'
import * as Queries from "./_gql" 

export function getAllUnidades({timezone='UTC',filter,orderBy}:any) {
  return Client.query(Queries.GQL_QUERY_UNIDADES(timezone,filter || {},orderBy||[{field:"unid_nombre",type:"asc"}],{}));
}


const getUnidades = ({timezone='UTC',params}:any): Promise<UnidadesQueryResponse> => {
  let condiciones: any = []
  if (params.search && params.search !== "")
    condiciones.push({ field: "unid_nombre", operador: "ILIKE", value: params.search })
  if (params.filter)
    Object.keys(params.filter).forEach((key: any) => {
      condiciones.push({ field: key, value: params.filter[key] })
    })

  return Client.query(Queries.GQL_QUERY_UNIDADES(timezone,(condiciones.length > 0) ? { condiciones: condiciones } : {}, [{ field: params.sort || "unid_creacion", type: params.order || "desc" }], { pagina: params.page || 1, cantidad: params.items_per_page })).then((d: any) => {
    return {
      data: d.data.unidades.data,
      payload: {
        pagination: getPagination(params, d.data.unidades.totalCount)
      }
    }
  });
  /*return axios
    .get(`${GET_UNIDADES_URL}?${query}`)
    .then((d: AxiosResponse<UnidadesQueryResponse>) => d.data)*/
}

export function getAllMonedas({timezone,moneda,filter,orderBy}:any) {
  return Client.query(Queries.GQL_QUERY_MONEDAS(timezone,moneda,filter || {},orderBy||[{field:"unid_nombre",type:"asc"}],{}));
}

const getUnidadById = ({timezone = 'UTC',unid_id}: any): Promise<Unidad | undefined> => {
  return Client.query(Queries.GQL_QUERY_UNIDADES(timezone,{ condiciones: [{ field: "unid_id", value: unid_id }] }, [], {})).then((d: any) => {
    return d.data.unidades.data[0]
  });
  /*return axios
    .get(`${UNIDAD_URL}/${id}`)
    .then((response: AxiosResponse<Response<Unidad>>) => response.data)
    .then((response: Response<Unidad>) => response.data)*/
}

const createUnidad = ({timezone = 'UTC',values}: any): Promise<Unidad | undefined> => {
  const {unid_nombre,unid_descripcion,unid_magn_id,unid_factor_multi_comun,unid_defecto,unid_activo} = values
   return Client.mutate(Queries.GQL_MUTATION_INSERT_UNIDAD(timezone,{ unid_nombre, unid_descripcion,unid_magn_id,unid_factor_multi_comun:(unid_factor_multi_comun)?Number(unid_factor_multi_comun):unid_factor_multi_comun,unid_activo:(unid_activo)?Number(unid_activo):unid_activo,unid_defecto:(unid_defecto)?Number(unid_defecto):unid_defecto })).then((d: any) => {
    return d.data.insertUnidad
  });
  /*return axios
    .put(UNIDAD_URL, unidad)
    .then((response: AxiosResponse<Response<Unidad>>) => response.data)
    .then((response: Response<Unidad>) => response.data)*/
}

const updateUnidad = ({timezone = 'UTC',values}:any): Promise<Unidad | undefined> => {
  const {unid_nombre,unid_descripcion,unid_magn_id,unid_factor_multi_comun,unid_defecto,unid_activo} = values
  return Client.mutate(Queries.GQL_MUTATION_UPDATE_UNIDAD(timezone,{ condiciones: [{ field: "unid_id", value: values.unid_id }] }, { unid_nombre, unid_descripcion,unid_magn_id,unid_factor_multi_comun:(unid_factor_multi_comun)?Number(unid_factor_multi_comun):unid_factor_multi_comun,unid_activo:(unid_activo)?Number(unid_activo):unid_activo,unid_defecto:(unid_defecto)?Number(unid_defecto):unid_defecto })).then((d: any) => {
    return undefined
  })
  /*return axios
    .post(`${UNIDAD_URL}/${unidad.id}`, unidad)
    .then((response: AxiosResponse<Response<Unidad>>) => response.data)
    .then((response: Response<Unidad>) => response.data)*/
}

const deleteUnidad = ({timezone = 'UTC',unid_id}: any): Promise<void> => {
  return Client.mutate(Queries.GQL_MUTATION_DELETE_UNIDAD(timezone,{ condiciones: [{ field: "unid_id", value: unid_id }] })).then((d: any) => {
    return d
  })
  //return axios.delete(`${UNIDAD_URL}/${unidadId}`).then(() => {})
}

const deleteSelectedUnidades = ({timezone = 'UTC',unid_ids}: any): Promise<void> => {
  return Client.mutate(Queries.GQL_MUTATION_DELETE_UNIDAD(timezone,{ condiciones: [{ field: "unid_id", operador: "IN", value: unid_ids.map((i: any) => `'${i}'`).join(',') }] })).then((d: any) => {
    return d
  })

  /*const requests = unidadIds.map((id) => axios.delete(`${UNIDAD_URL}/${id}`))
  return axios.all(requests).then(() => {})*/
}

export { getUnidades, deleteUnidad, deleteSelectedUnidades, getUnidadById, createUnidad, updateUnidad }
