import {createSlice} from "@reduxjs/toolkit";

const initialVariablesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  variableForEdit: undefined,
  lastError: null,
  queryParams: undefined
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const variablesSlice = createSlice({
  name: "variables",
  initialState: initialVariablesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProductById
    variableFetched: (state, action) => {
      state.actionsLoading = false;
      state.variableForEdit = action.payload.variableForEdit;
      state.error = null;
    },
    // findProducts
    variablesFetched: (state, action) => {
      const { queryParams, totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.queryParams = queryParams;
    },
    // createProduct
    variableCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.unshift(action.payload.variable);
    },
    // updateProduct
    variableUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.vari_id === action.payload.variable.vari_id) {
          return action.payload.variable;
        }
        return entity;
      });
    },
    // deleteProduct
    variableDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.vari_id !== action.payload.vari_id);
    },
    // deleteProducts
    variablesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.vari_id)
      );
    },
    // productsUpdateState
    variablesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(vari_id => vari_id === entity.vari_id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
