import { FC, useMemo } from 'react'
import clsx from 'clsx'
import { KTSVG } from '../../../helpers'
import {
  TablerosFiltro,
} from '../../../partials'
import { useHeaderToolbarContext } from './UIContext'
//import { useLayout } from '../../core'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  //const { config } = useLayout()

  const HeaderToolbarContext = useHeaderToolbarContext()
  const HeaderToolbarProps = useMemo(() => {
    return {
      opciones: HeaderToolbarContext.opciones,
    };
  }, [HeaderToolbarContext]);

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Quick links */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* begin::Menu wrapper */}


            <span className='fs-7 text-gray-700 fw-bolder pe-3 d-none d-xxl-block'>
              Opciones:
            </span>
            <div
              className={clsx(
                'btn btn-icon btn-custom w-30px h-30px w-md-40px h-md-40px',
                (HeaderToolbarProps.opciones.length>0)?'btn-active-light-primary':''
              )}
              data-kt-menu-trigger={(HeaderToolbarProps.opciones.length>0)?'click':''}
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen019.svg'
                className={toolbarButtonIconSizeClass}
              />
            </div>

        <TablerosFiltro />
        {/* end::Menu wrapper */}
      </div>


    </div>
  )
}

export { Topbar }
