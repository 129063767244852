import {createSlice} from "@reduxjs/toolkit";

const initialEdificiosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  edificioForEdit: undefined,
  lastError: null,
  queryParams: undefined
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const edificiosSlice = createSlice({
  name: "edificios",
  initialState: initialEdificiosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProductById
    edificioFetched: (state, action) => {
      state.actionsLoading = false;
      state.edificioForEdit = action.payload.edificioForEdit;
      state.error = null;
    },
    // findProducts
    edificiosFetched: (state, action) => {
      const { queryParams, totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.queryParams = queryParams;
    },
    // createProduct
    edificioCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.edificio);
    },
    // updateProduct
    edificioUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.edif_id === action.payload.edificio.edif_id) {
          return action.payload.edificio;
        }
        return entity;
      });
    },
    // deleteProduct
    edificioDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.edif_id !== action.payload.edif_id);
    },
    // deleteProducts
    edificiosDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.edif_id)
      );
    },
    // productsUpdateState
    edificiosStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(edif_id => edif_id === entity.edif_id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
