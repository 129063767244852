

import { StylesConfig } from 'react-select';

const colourStyles = (mode: string): StylesConfig<any> => ({
    menuPortal: base => {
        return ({ ...base, zIndex: 9999 })
    },
    singleValue:(styles: any, { isDisabled }) => {
        return ({
            ...styles,
            color: isDisabled 
                ? "gray" 
                :(mode === 'light')
                    ? 'black'
                    : 'white'
        })
    }, 
    control: (styles: any, { isDisabled }) => {
        return ({
            ...styles,
            backgroundColor:
                isDisabled
                    ? (mode === 'light')
                        ? '#ECECEC'
                        : '#8D8D8D'
                    : (mode === 'light')
                        ? 'white'
                        : '#1e1e1e',
            color: isDisabled ? "red" : "black"
        })
    },
    option: (styles: any, { data, isFocused, isDisabled, isSelected }: any) => {
        //console.log(styles)
        return ({
            ...styles,
            backgroundColor:
                isDisabled
                    ? 'gray'
                    : isSelected
                        ? '#35637A'
                        : isFocused
                            ? '#9DBECE'
                            : (mode === 'light') ?
                                'white' :
                                '#1e1e1e',
        })
    }
}
)

export { colourStyles }