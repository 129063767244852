import { gql } from '@apollo/client'

export const GQL_QUERY_HISTORICOS_PERMITIDOS = (filter, orderBy, paginacion) => {
  return {
    query: gql`query historicosPermitidos($filter:filter,$orderBy:[orderBy],$paginacion:paginacion){
                  historicosPermitidos(filter:$filter,orderBy:$orderBy,paginacion:$paginacion){
                    ...on historicosPermitidos {
                      totalCount
                      data{
                        perm_id
                        perm_enti_id
                        perm_usua_id
                        perm_defecto
                        perm_propietario
                        hist_id
                        hist_tabl_id
                        hist_inst_id
                        hist_vari_id
                        hist_dias_atras
                        hist_tabl_enti_id
                        hist_descripcion
                        hist_nombre
                        hist_creacion
                        hist_activo
                      }
                    }
                    ...on msg{
                      mensaje
                    }
                  }
                }
              `,
    variables: {
      filter: filter, orderBy: orderBy, paginacion: paginacion,
    },
    //fetchPolicy:"network-only"
  }
}











