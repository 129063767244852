import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { setContext } from '@apollo/client/link/context';
import { getAuth} from '../auth/core/AuthHelpers'
//import store from "../../../redux/store";

const server = (process.env.NODE_ENV === 'development')?'http://localhost:4013/graphql':'https://portal3.ribe.cl:4013/graphql';

const httpLink = createHttpLink({
  uri:server,
  //uri: 'http://186.64.123.2:4013/graphql'
});

const errorLink = onError(({ operation,response, graphQLErrors, networkError }) => {
  //console.log(operation)
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      ({ message, locations, path })
     //console.log('GraphQL error', { message, locations, path })
    );
   }
  
   if (networkError) {
    const { name, statusCode, result,message = {} } = networkError;
    console.log('Network error', { name, statusCode, result, message });
    console.log(networkError.message)
    networkError.message = `Error de Conexion, al intentar realizar la operacion ${operation.operationName}, consulte al Administrador`
   }
  /*if (graphQLErrors)
    console.log(graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
          console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
      return null;
    }
 

    );
  if (networkError) {
    console.log("networkError:", networkError)
    console.log(`[Network error]: ${networkError}`);
  }*/
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  /*const {
    auth: { accessToken }
  } = store.getState();*/
  // return the headers to the context so httpLink can read them
  const auth = getAuth()
  return {
    headers: {
      ...headers,
      authorization: (auth && auth.accessToken) ? `Bearer ${auth.accessToken}` : "",
    }
  }
});



const Client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache({
    dataIdFromObject(object) {
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  }
});


export default Client;