export * from "./helpers/";
export { Loading, Loading2 } from "./components/Loading";
export { downloadExcel } from "./components/ExportXLSX"
export * from "./components/SortedColumns"
export * from "./components/Pagination"
export * from "./components/render"
export * from "./components/styles/"

export const meses = {
    c: 'Ene.Feb.Mar.Abr.May.Jun.Jul.Ago.Sep.Oct.Nov.Dic',
    l: 'Enero.Febrero.Marzo.Abril.Mayo.Junio.Julio.Agosto.Septiembre.Octubre.Noviembre.Diciembre'
};

export const dias = {
    c: 'Dom.Lun.Mar.Mie.Jue.Vie.Sab',
    l: 'Domingo.Lunes.Martes.Miercoles.Jueves.Viernes.Sabado'
};


export {}