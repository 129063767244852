import { useMemo, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { useHeaderToolbarContext } from '../../../_metronic/layout/components/header/UIContext'
import  WidgetsPage  from "./Widgets"

const DashboardPage = () => {

  const HeaderToolbarContext = useHeaderToolbarContext();
  const HeaderToolbarProps = useMemo(() => {
    return {
      //edifProps: HeaderToolbarContext.edifProps,
      opcion: HeaderToolbarContext.opcion,
      setOption: HeaderToolbarContext.setOpcion,
      props: HeaderToolbarContext.props,
    };
  }, [HeaderToolbarContext]);

  useEffect(() => {
    HeaderToolbarProps.setOption(2) //Barra de Herramientas de Edificio
  }, [])



  return (
    <>
      {/* begin::Row */}
        <WidgetsPage props={HeaderToolbarProps.props[HeaderToolbarProps.opcion]}/>
      {/* end::Row */}

    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
