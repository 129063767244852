import { createContext, useContext, useState } from "react";
import moment from "moment";


const HeaderToolbarContext = createContext();

export function useHeaderToolbarContext() {
  return useContext(HeaderToolbarContext);
}

export const HeaderToolbarConsumer = HeaderToolbarContext.Consumer;

export function HeaderToolbarProvider({children }) {
  const [opciones,setOpciones] = useState([])
  const [loading,setLoading] = useState(false)
  const [opcion,setOpcion] = useState(0)
  const [dias,setDias] = useState(7)
  const [agrupar,setAgrupar] = useState('horas')
  const [mostrar,setMostrar] = useState('energia')
  const [mes,setMes] = useState(+moment().format('M'))
  const [energia,setEnergia] = useState(true)
  //const [edifProps, setEdifProps] = useState({id:undefined, disabled: true, loading: false, data: [] })
  const [unidEnergiaProps, setUnidEnergiaProps] = useState({id:undefined, disabled: true, loading: false, data: [] })
  const [unidPotenciaProps, setUnidPotenciaProps] = useState({id:undefined, disabled: true, loading: false, data: [] })
  const [unidMontoProps, setUnidMontoProps] = useState({id:undefined, disabled: true, loading: false, data: [] })
  const [variProps, setVariProps] = useState({id:undefined, disabled: true, loading: false, data: [] })
  const [tablProps, setTablProps] = useState({id:undefined, disabled: true, loading: false, data: [] })
  const [edifProps, setEdifProps] = useState({id:undefined, disabled: true, loading: false, data: [] })
  const [props,setProps] = useState([
    {id:undefined, disabled: true, loading: false, data: [] },
    {id:undefined, disabled: true, loading: false, data: [] },
    {id:undefined, disabled: true, loading: false, data: [] }
  ])

  const value = {
    opciones,setOpciones,
    dias, setDias,
    mes,setMes,
    agrupar,setAgrupar,
    mostrar,setMostrar,
    loading,setLoading,
    opcion,setOpcion,
    unidEnergiaProps,setUnidEnergiaProps,
    unidPotenciaProps,setUnidPotenciaProps,
    unidMontoProps,setUnidMontoProps,
    props,setProps,
    energia,setEnergia,
    variProps,setVariProps,
    tablProps,setTablProps,
    edifProps,setEdifProps
  };


  return (
    <HeaderToolbarContext.Provider value={value}>
      {children}
    </HeaderToolbarContext.Provider>
  );
}
