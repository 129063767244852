import { gql } from '@apollo/client'

export const GQL_QUERY_TABLEROS = (timezone: string, filter: any, orderBy: any, paginacion: any) => {
  return {
    query: gql`query tableros($timezone:String,$filter:filter,$orderBy:[orderBy],$paginacion:paginacion){
                      tableros(timezone:$timezone,filter:$filter,orderBy:$orderBy,paginacion:$paginacion){
                        ...on tableros {
                          totalCount
                          data{
                            tabl_id
                            tabl_enti_id
                            tabl_nombre
                            tabl_activo
                            tabl_creacion
                            tabl_descripcion
                            tabl_configuracion
                            tabl_usua_id
                            tabl_usua_info
                            }
                          }
                        ...on msg{
                          mensaje
                        }
                      }
                    }
                  `,
    variables: {
      timezone, filter, orderBy, paginacion
    },
    //fetchPolicy:"network-only"
  }
}

export const GQL_QUERY_WIDGETS = (timezone: string, filter: any, orderBy: any, paginacion: any) => {
  return {
    query: gql`query widgets($timezone:String,$filter:filter,$orderBy:[orderBy],$paginacion:paginacion){
                      widgets(timezone:$timezone,filter:$filter,orderBy:$orderBy,paginacion:$paginacion){
                        ...on widgets {
                          totalCount
                          data{
                            widg_id
                            widg_enti_id
                            widg_nombre
                            widg_activo
                            widg_creacion
                            widg_tabl_id,
                            widg_tabl_nombre
                            widg_tabl_descripcion
                            widg_configuracion
                            }
                          }
                        ...on msg{
                          mensaje
                        }
                      }
                    }
                  `,
    variables: {
      timezone, filter, orderBy, paginacion
    },
    //fetchPolicy:"network-only"
  }
}

export const GQL_QUERY_VARIABLE_HISTORICA = (parametros: any) => {
  return {
    query: gql`query Query($parametros: JSONObject) {
    widgVariableHistorica(parametros: $parametros)
  }
  `,
    variables: {
      parametros
    },

  }
}

export const GQL_QUERY_ESTADO_ENTIDAD = (timezone: string, parametros: any) => {
  return {
    query: gql`query Query($timezone:String,$parametros: JSONObject) {
      widgEstadoEntidad(timezone:$timezone,parametros: $parametros)
  }
  `,
    variables: {
      timezone,
      parametros
    },

  }
}

export const GQL_QUERY_RESUMEN_ESTADO = (timezone: string, parametros: any, fecha_inicial:string,fecha_final:string) => {
  return {
    query: gql`query Query($timezone:String,$parametros: JSONObject,$fecha_inicial:String,$fecha_final:String) {
      widgResumenEstado(timezone:$timezone,parametros: $parametros,fecha_inicial:$fecha_inicial,fecha_final:$fecha_final)
  }
  `,
    variables: {
      timezone,
      parametros,
      fecha_inicial,
      fecha_final,
    },

  }
}

export const GQL_QUERY_INFO_ESCANEO = (timezone: string, esca_id:string) => {
  return {
    query: gql`query Query($timezone:String,$esca_id: String!) {
      infoescaneos(timezone:$timezone,esca_id:$esca_id){
        ... on infoescaneos {
      totalCount
      data {
        esca_id
        esca_fecha
        esca_equi_online
        esca_equi_offline
        esca_equi_nuevos
        esca_equi_recuperados
        esca_equi_deshabilitados
      }
    }        
      }
  }
  `,
    variables: {
      timezone,
      esca_id
    },

  }
}

export const GQL_QUERY_VARIABLE_HISTORICA_AGRUPADA = (agrupar: string, parametros: any) => {
  return {
    query: gql`query Query($agrupar:String,$parametros: JSONObject) {
    widgVariableHistoricaAgrupada(agrupar:$agrupar,parametros: $parametros)
  }
  `,
    variables: {
      agrupar,
      parametros
    },

  }
}



