import Client from '../../../../graphql/client'
import * as Queries from "./GQL"

// READ
export function getAllEficienciasPermitidas({filter,orderBy}:any) {
  return Client.query(Queries.GQL_QUERY_EFICIENCIAS_PERMITIDAS(filter || {},orderBy || [{field:"efic_nombre",type:"asc"}],{}));
}

export function getVariableHistorica(parametros:any) {
  //console.log(parametros)
    return Client.query(Queries.GQL_QUERY_VARIABLE_HISTORICA(parametros));
}

export function getVariableHistoricaPeriodo(parametros:any) {
  return Client.query(Queries.GQL_QUERY_VARIABLE_HISTORICA_PERIODO(parametros));
}

export function getVariableHistoricaPeriodoComparativo(parametros:any) {
  return Client.query(Queries.GQL_QUERY_VARIABLE_HISTORICA_PERIODO_COMPARATIVO(parametros));
}


