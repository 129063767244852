import Client from '../../../../graphql/client'
import * as Queries from "./_gql"

const getAllTableros = ({timezone='UTC',filter,orderBy}:any) => {
  return Client.query(Queries.GQL_QUERY_TABLEROS(timezone,filter || {},orderBy||[{field:"tabl_nombre",type:"asc"}],{}));
}

const getAllWidgets = ({timezone='UTC',filter,orderBy}:any) => {
  return Client.query(Queries.GQL_QUERY_WIDGETS(timezone,filter || {},orderBy||[{field:"widg_posicion",type:"asc"}],{}));
}

export function getVariableHistorica(parametros:any) {
  //console.log(parametros)
    return Client.query(Queries.GQL_QUERY_VARIABLE_HISTORICA(parametros));
}
export function getEstadoEntidad(timezone:string,parametros:any) {
  //console.log(parametros)
    return Client.query(Queries.GQL_QUERY_ESTADO_ENTIDAD(timezone,parametros));
}

export const getResumenEstado = (timezone:string,parametros:any,fecha_inicial:string,fecha_final:string) =>{
  return Client.query(Queries.GQL_QUERY_RESUMEN_ESTADO(timezone,parametros,fecha_inicial,fecha_final));
}

export function getInfoEscaneo(timezone:string,esca_id:string) {
  return Client.query(Queries.GQL_QUERY_INFO_ESCANEO(timezone,esca_id))
}

export function getVariableHistoricaAgrupada(agrupar:string,parametros:any) {
  //console.log(parametros)
    return Client.query(Queries.GQL_QUERY_VARIABLE_HISTORICA_AGRUPADA(agrupar,parametros));
}






export { getAllTableros, getAllWidgets }
