//import {all} from "redux-saga/effects";
import { combineReducers } from "redux";

import { edificiosSlice } from "../app/modules/mediciones/_redux/edificios/core/Slice";
import { serviciosSlice } from "../app/modules/mediciones/_redux/servicios/core/Slice";
import { instrumentosSlice } from "../app/modules/mediciones/_redux/instrumentos/core/Slice";
import { variablesSlice } from "../app/modules/mediciones/_redux/variables/core/Slice";
import { puntosSlice } from "../app/modules/mediciones/_redux/puntos/core/Slice";
import { unidadesSlice } from "../app/modules/mediciones/_redux/unidades/core/Slice";

export const rootReducer = combineReducers({
  //auth: auth.reducer,
  edificios: edificiosSlice.reducer,
  servicios: serviciosSlice.reducer,
  instrumentos: instrumentosSlice.reducer,
  variables: variablesSlice.reducer,
  puntos: puntosSlice.reducer,
  unidades: unidadesSlice.reducer
});

export function* rootSaga() {
  //yield all([auth.saga()]);
}
