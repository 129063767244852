import { KTSVG } from "../../../../_metronic/helpers"

export const getPagination = (params: any, total: number, pagesCount: number = 2) => {
  let currentPage = params.page || 1
  let items_per_page = params.items_per_page || 10
  let links: any = []
  const pages = Math.ceil(total / items_per_page);
  let from: number = ((currentPage - pagesCount) >= 1) ? (currentPage - pagesCount) : 1
  let to: number = ((from + (pagesCount * 2)) <= pages) ? (from + (pagesCount * 2)) : pages
  from = ((to - from) < 4) ? ((to - (pagesCount * 2)) >= 1) ? (to - (pagesCount * 2)) : 1 : from
  if (total > 0) {
    //Agregar link de Pagina Inicial
    links.push({
      id:'page_start',
      url: (currentPage > 1) ? "/?page=1" : null,
      label: "<<",
      active: false,
      page: (currentPage > 1) ? 1 : null,
    })
    //Agregar link de Pagina Anterior
    links.push({
      id:'page_previous',
      url: (currentPage > 1) ? `/?page=${currentPage - 1}` : null,
      label: "<",
      active: false,
      page: (currentPage > 1) ? (currentPage - 1) : null,
    })
    //Agregar link de Ellipsis Pagina Inicial
    if (from > 1)
      links.push({
        id:'ellipsis_start',
        url: null,
        label: "...",
        active: false,
        page: null,
      })
    //Agregar link con Numeros de Paginas
    for (let i = from; i <= to; i++) {
      links.push({
        id:`page_${i}`,
        url: `/?page=${i}`,
        label: String(i),
        active: (currentPage === i),
        page: i,
      })
    }
    //Agregar link de Ellipsis Pagina Final
    if (to < pages)
      links.push({
        id:"ellipsis_last",
        url: null,
        label: "...",
        active: false,
        page: null,
      })
    //Agregar link de Pagina Siguiente
    links.push({
      id:"page_next",
      url: (currentPage < pages) ? `/?page=${currentPage + 1}` : null,
      label: ">",
      active: false,
      page: (currentPage < pages) ? (currentPage + 1) : null,
    })
    //Agregar link de Pagina Final
    links.push({
      id:"page_last",
      url: (currentPage < pages) ? `/?page=${pages}` : null,
      label: ">>",
      active: false,
      page: (currentPage < pages) ? pages : null,
    })
  }
  return {
    page: currentPage,
    items_per_page: items_per_page,
    total: total,
    prev_page_url: null,
    next_page_url: "/?page=2",
    from: (currentPage - 1) * (items_per_page) + 1,
    to: (currentPage * items_per_page),
    last_page: pages,
    links: links
  }
}

export const mappedLabel = (label: string) => {
    switch (label) {
      case 'First': return (<KTSVG path='/media/icons/duotune/arrows/arr021.svg' className='svg-icon-1' />)
      case 'Previous': return (<KTSVG path='/media/icons/duotune/arrows/arr022.svg' className='svg-icon-1' />)
      case 'Next': return (<KTSVG path='/media/icons/duotune/arrows/arr023.svg' className='svg-icon-1' />)
      case 'Last': return (<KTSVG path='/media/icons/duotune/arrows/arr024.svg' className='svg-icon-1' />)
      case 'EllipsisI':
      case 'EllipsisF': return (<KTSVG path='/media/icons/duotune/general/gen052.svg' className='svg-icon-1' />)
      default: return (label)
    }
  }
  
export const makeLinks = (recordCount: number, currentPage: number, recordPerPage: number, pagesCount: number = 2) => {
  
    let links:any = []
    const pages = Math.ceil(recordCount / recordPerPage);
    //const from:number = ((currentPage-(pagesCount*2)) >= 1)?(currentPage-(pagesCount*2)):1
    //const to:number = ((from + (pagesCount*2)) <= pages)?(from + (pagesCount*2)):pages
    let from: number = ((currentPage - pagesCount) >= 1) ? (currentPage - pagesCount) : 1
    let to: number = ((from + (pagesCount * 2)) <= pages) ? (from + (pagesCount * 2)) : pages
    from = ((to - from) < 4) ? ((to - (pagesCount * 2)) >= 1)?(to - (pagesCount * 2)):1: from
    //console.log('from:',from)
    //const to:number = ((currentPage+pagesCount) <= pages)?(currentPage+pagesCount):pages
    if (recordCount > 0) {
      //Agregar link de Pagina Inicial
      links.push({
        url: (currentPage > 1) ? "/?page=1" : null,
        label: "First",
        active: false,
        page: (currentPage > 1) ? 1 : null,
      })
      //Agregar link de Pagina Anterior
      links.push({
        url: (currentPage > 1) ? `/?page=${currentPage - 1}` : null,
        label: "Previous",
        active: false,
        page: (currentPage > 1) ? (currentPage - 1) : null,
      })
      //Agregar link de Ellipsis Pagina Inicial
      if (from > 1)
        links.push({
          url: null,
          label: "EllipsisI",
          active: false,
          page: null,
        })
  
      //Agregar link con Numeros de Paginas
      for (let i = from; i <= to; i++) {
        links.push({
          url: `/?page=${i}`,
          label: String(i),
          active: (currentPage === i),
          page: i,
        })
      }
      //Agregar link de Ellipsis Pagina Final
      if (to < pages)
        links.push({
          url: null,
          label: "EllipsisF",
          active: false,
          page: null,
        })
      //Agregar link de Pagina Siguiente
      links.push({
        url: (currentPage < pages) ? `/?page=${currentPage + 1}` : null,
        label: "Next",
        active: false,
        page: (currentPage < pages) ? (currentPage + 1) : null,
      })
      //Agregar link de Pagina Final
      links.push({
        url: (currentPage < pages) ? `/?page=${pages}` : null,
        label: "Last",
        active: false,
        page: (currentPage < pages) ? pages : null,
      })
    }
    return links
  }

  export {}